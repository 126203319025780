import { DateTime, Interval } from 'luxon';
import { DayModel } from './day.model';
import { WeekViewRowModel } from './weekviewrow.model';

export class WeekModel {
  weekNumber = 0;
  days: DayModel[] = [];
  weekViewRows: WeekViewRowModel[] = [];
  firstDay: DateTime;
  lastDay: DateTime;
  RESIZER_FOR_SCROLLING = 10;

  get timespan(): Interval {
    return Interval.fromDateTimes(this.firstDay, this.lastDay);
  }

  /**
   * @param dateInWeek some date in wished week.
   */
  constructor(dateInWeek: DateTime) {
    this.RESIZER_FOR_SCROLLING = 10;
    this.weekNumber = dateInWeek.weekNumber;
    this.firstDay = dateInWeek.startOf('week').startOf('day');
    this.lastDay = dateInWeek.endOf('week').endOf('day');
  }

  get dateArray(): DateTime[] {
    const arr: DateTime[] = [];
    let date = this.firstDay;
    while (date <= this.lastDay)  {
      arr.push(date);
      date = date.plus({days: 1}).startOf('day');
    }
    return arr;
  }
}
