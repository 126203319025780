import { IEventModel } from './event.model';

export class WeekViewRowModel{
  hour: number;
  cells: IWeekViewRowCell[];
  evtCounter: number = 0;

  constructor(hr: number, cells: IWeekViewRowCell[]) {
    this.hour = hr;
    this.cells = cells;
  }

}

export interface IWeekViewRowCell {
  events: IEventModel[];
  dayNum: number;
  isVisible: boolean;
  isToday?: boolean;
}
