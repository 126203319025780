import { DateTime, Interval } from 'luxon';
import { WeekModel } from './week.model';

export class MonthModel {
  weeks: WeekModel[] = [];
  startDate: DateTime;
  endDate: DateTime;
  events: any[] = [];
  isExpanded: boolean = false;
  isCurrentMonth: boolean = false;

  get timespan(): Interval {
    return Interval.fromDateTimes(this.startDate, this.endDate);
  }
  /**
   * @param dateInMonth some date in wished month.
   */
  constructor(dateInMonth: DateTime, isYearView: boolean = false) {
    this.isCurrentMonth = dateInMonth.hasSame(DateTime.now(), 'month');
    if(isYearView){
      this.startDate = dateInMonth.startOf('month').startOf('day');
      this.endDate = dateInMonth.endOf('month').endOf('day');
    }
    else {
      this.startDate = dateInMonth.startOf('month').startOf('week').startOf('day');
      this.endDate = dateInMonth.endOf('month').endOf('week').endOf('day');
    }
  }

  get dateArray(): DateTime[] {
    const arr: DateTime[] = [];
    let date = this.startDate;
    while (date <= this.endDate)  {
      arr.push(date);
      date = date.plus({days: 1});
    }
    return arr;
  }
}
