import { DateTime } from 'luxon';
import { DayModel } from './day.model';

export class ListModel {
  startDate: DateTime;
  endDate: DateTime;
  days: DayModel[] = [];

  /**
   * create a new listmodel
   * @param dateInPeriod datetime in wished peroid
   */
  constructor(dateInPeriod: DateTime) {
    this.startDate = dateInPeriod.startOf('week');
    this.endDate = dateInPeriod.endOf('week');
  }

  /**
   * all dates in model
   */
  get dateArray(): DateTime[] {
    const arr: DateTime[] = [];
    let date = this.startDate;
    while (date <= this.endDate) {
      arr.push(date);
      date = date.plus({days: 1});
    }
    return arr;
  }
}