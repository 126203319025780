<!-- buttons voor npm, github, darkmode en menu-togle -->
<a class="side-button-npm" href="https://www.npmjs.com/package/ngtr-calendar" target="_blank">
  <fa-icon [icon]="fontAwesome.npm"></fa-icon>
</a>
<a class="side-button-github" href="https://github.com/timroos1990/ngtr-calendar" target="_blank">
  <fa-icon [icon]="fontAwesome.github"></fa-icon>
</a>
<button class="side-button-menu-toggle" (click)="isCalendarFullwidth = !isCalendarFullwidth">
  <fa-icon [icon]="fontAwesome.menuSide"></fa-icon>
</button>

<section class="hero is-bold is-primary">
  <div class="hero-body">
    <h1 class="title is-3">
      ngtr-calendar
    </h1>
    <h2 class="subtitle is-5">
      calendar.troos.nl
    </h2>
  </div>

</section>
<div class="columns" >
  <div class="column is-one-quarter" *ngIf="!isCalendarFullwidth">
    <nav class="panel" [ngClass]="{'is-success': calendarDataTab == 0, 'is-info': calendarDataTab == 1, 'is-link': calendarDataTab == 4, 'is-logging': calendarDataTab == 2}">
      <div class="panel-heading">NgtrCalendar </div>
      <div class="panel-block is-active">
        <span class="panel-icon">
          <fa-icon [icon]="fontAwesome.npm"></fa-icon>
        </span>
        <p>ngtr-calendar version: <b>{{ ngtrCalendarVersion }}</b></p>
      </div>
      <div class="panel-block is-active">
        <span class="panel-icon">
          <fa-icon [icon]="fontAwesome.npm"></fa-icon>
        </span>
        <p>ngtr-tiny-calendar version: <b>{{ ngtrTinyCalendarVersion }}</b></p>
      </div>
      <div class="panel-tabs">
        <a [ngClass]="{'is-active': calendarDataTab == 0}" (click)="calendarDataTab = 0">TinyCalendar</a>
        <a [ngClass]="{'is-active': calendarDataTab == 1}" (click)="calendarDataTab = 1">Settings</a>
        <a [ngClass]="{'is-active': calendarDataTab == 3}" (click)="calendarDataTab = 3">Data</a>
        <a [ngClass]="{'is-active': calendarDataTab == 2}" (click)="calendarDataTab = 2">Log</a>
      </div>

      <ng-container [ngSwitch]="calendarDataTab">

        <!-- ngtr-tiny-calendar -->
        <ng-container *ngSwitchCase="0">
          <div class="panel-block is-active">
            <span class="panel-icon">
              <fa-icon [icon]="fontAwesome.calendar"></fa-icon>
            </span>
            <b>viewDate</b>: {{viewDate.toFormat('dd-MM-yyyy')}}
          </div>
          <div class="panel-block">
            <ngtr-tiny-calendar
              style="width: 100%;"
              [viewDate]="viewDate"
              [today]="viewDate"
              [minDate]="calendarService.minDateTime"
              [maxDate]="calendarService.maxDateTime"
              [markedDates]="markedDateTimes"
              (viewDateChanged)="onTinyViewDateChanged($event)"></ngtr-tiny-calendar>
          </div>
          <div class="panel-block">
            <button class="button is-primary is-fullwidth" (click)="addEvent()">Add event</button>
          </div>
        </ng-container>

        <!-- Settings -->
        <ng-container *ngSwitchCase="1">
          <div class="panel-block"><b>min date</b>: {{calendarService.minDateTime.toFormat('dd-MM-yyyy')}}</div>
          <div class="panel-block"><b>max date</b>: {{calendarService.maxDateTime.toFormat('dd-MM-yyyy')}}</div>

          <div class="panel-block"><b>currentCalendarType</b>: {{currentCalendarType}}</div>
          <div class="panel-block"><b>calendar types</b>:</div>
          <div>
            <pre> {{calendarTypes | json}} </pre>
          </div>
          <div class="panel-block"> <b>visibleDays</b>:</div>
          <div>
            <pre> {{visibleDays | json}} </pre>
          </div>
        </ng-container>

        <!-- Log data-->
        <ng-container *ngSwitchCase="2">
          <div class="panel-block">Output van de kalender. Gebruik F12 voor meer informatie</div>
          <div *ngIf="consoleStr.length != 0">
            <pre>{{consoleStr | json}}</pre>
          </div>
          <div class="panel-block" *ngIf="consoleStr.length != 0">
            <button class="button is-small is-fullwidth is-warning" (click)="consoleStr = [];">Reset list</button>
          </div>
        </ng-container>

        <!-- inputdata -->
        <ng-container *ngSwitchCase="3">
          <div class="panel-block">
            <b>Events</b>: {{events.length}} events
          </div>
          <pre>{{events | json}}</pre>
          <div class="panel-block">
            <b>WorkHours</b>: {{workHours.length}} workhourmodels
          </div>
          <pre>{{workHours | json}}</pre>
          <div class="panel-block">
            <b>contextActions</b>: {{ actions.length }} contextActions
          </div>
          <pre>{{actions | json}}</pre>
        </ng-container>

      </ng-container>
    </nav>
  </div>
  <div class="column" [ngClass]="{'is-three-quarters': !isCalendarFullwidth}">
    <ngtr-calendar
      [calendarTypes]="calendarTypes"
      [events]="events"
      [workHours]="workHours"
      [viewDate]="viewDate"
      [currentCalendarType]="currentCalendarType"
      (viewDateChanged)="onViewDateChanged($event)"
      (eventCreate)="onEventCreate($event)"
      (eventDelete)="onEventDelete($event)"
      (eventSelected)="onEventSelected($event)"
      (contextAction)="onContextActionSelected($event)">
    </ngtr-calendar>
  </div>
</div>

