import { NgModule } from '@angular/core';
import { NgtrCalendarComponent } from './ngtr-calendar.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronDown, faChevronUp, faChevronLeft, faChevronRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { WeekComponent } from './calendar-views/week/week.component';
import { MonthComponent } from './calendar-views/month/month.component';
import { FourweeksComponent } from './calendar-views/fourweeks/fourweeks.component';
import { FormsModule } from '@angular/forms';
import { EventBlockComponent } from './calendar-event-views/eventblock/eventblock.component';
import { EventCellComponent } from './calendar-event-views/eventcell/eventcell.component';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from './tooltip/tooltip.directive';
import { TooltipContainerComponent } from './tooltip/tooltip.container.component';
import { TooltipContentComponent } from './tooltip/tooltip.component';
import { TooltipService } from './tooltip/tooltip.service';
import { ContextmenuComponent } from './contextmenu/contextmenu.component';
import { ListComponent } from './calendar-views/list/list.component';
import { YearComponent } from './calendar-views/year/year.component';
import { CalendarHeaderCalTypeComponent } from './calendar-header-items/calendar-type-header-item/header.calendartype.component';
import { CalendarHeaderViewdateComponent } from './calendar-header-items/calendar-viewdate-header-item/header.viewdate.component';
import { CalendarHeaderVisibleDaysComponent } from './calendar-header-items/calendar-visibledays-header-item/header.visibledays.component';
import { LuxonDateTimeStringPipe } from './pipes/luxonstring.pipe';

@NgModule({
  declarations: [
    NgtrCalendarComponent,
    WeekComponent,
    MonthComponent,
    FourweeksComponent,
    YearComponent,
    ListComponent,
    EventBlockComponent,
    EventCellComponent,
    TooltipDirective,
    TooltipContentComponent,
    TooltipContainerComponent,
    ContextmenuComponent,
    CalendarHeaderCalTypeComponent,
    CalendarHeaderViewdateComponent,
    CalendarHeaderVisibleDaysComponent,
    LuxonDateTimeStringPipe
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
  ],
  exports: [
    NgtrCalendarComponent,
  ],
  providers: [TooltipService]

})
export class NgtrCalendarModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faChevronDown, faChevronUp, faChevronLeft, faChevronRight, faPlus);
  }
}
