import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { NgtrTinyCalendarComponent } from './ngtr-tiny-calendar.component';



@NgModule({
  declarations: [NgtrTinyCalendarComponent],
  imports: [
    CommonModule,             
    FontAwesomeModule,        
    FormsModule,   
  ],
  exports: [NgtrTinyCalendarComponent]
})
export class NgtrTinyCalendarModule { 
  constructor(library: FaIconLibrary){
    library.addIcons(faChevronLeft, faChevronRight);
  }
}
