import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgtrCalendarModule } from 'ngtr-calendar';
import { NgtrTinyCalendarModule } from 'ngtr-tiny-calendar';

@NgModule({
  declarations: [
    AppComponent          // de enige component van dit project. Dit project is om de ngtr-calendar te kunnen testen.
  ],
  imports: [
    BrowserModule,        // om Angular te kunnen gebruiken
    FontAwesomeModule,    // voor het weergeven van iconen
    NgtrCalendarModule,   // het project dat getest kan worden (als NPM-package)
    NgtrTinyCalendarModule
  ],
  providers: [],          // geen providers.
  bootstrap: [
    AppComponent          // deze moet opgestart worden.
  ]
})
export class AppModule { }