import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { IEventModel } from '../../models/event.model';
import { IContextHelper } from '../../models/context.model';
import { NgtrCalendarService } from '../../ngtr-calendar.service';

@Component({
  selector: 'ngtr-agenda-event-cell',
  templateUrl: './eventcell.component.html',
  //styleUrls: ['./eventcell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class EventCellComponent {

  @Input() event: IEventModel;
  @Input() details = false;
  @Input() jaarView = false;
  @Input() bounds: any = undefined;
  @Input() lockedAxis: string = 'x';

  @Output() eventClicked: EventEmitter<number|string> = new EventEmitter<number|string>();
  @Output() eventRightClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventDragged: EventEmitter<number|string> = new EventEmitter<number|string>();

  isResizing: boolean = false;
  isHovered: boolean = false;

  constructor(public calendarService: NgtrCalendarService) { }

  onClick(evt: MouseEvent) {
    evt.stopPropagation();
    evt.preventDefault();
    if(this.calendarService.isBusy && !this.isResizing){ return; }
    else if(!this.event.isSelectable) { return; }
    else if (evt.altKey || evt.shiftKey || evt.ctrlKey || evt.button == 2) {
      if (!this.calendarService.isContextActionsEnabled) { return; }
      const obj: IContextHelper = {
        ownerDesc: this.event.title,
        eventId: this.event.id,
        clickEvent: evt,
        event: this.event,
        actionId: 0
      };
      this.eventRightClicked.emit(obj);
    }
    else if(!this.isResizing){
      this.eventClicked.emit(this.event.id);
    }
  }

}
