/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { IContextAction, ContextActionTypesEnum, EventModel, WorkHoursModel } from 'ngtr-calendar';
import { IMarkedDateTime } from 'ngtr-tiny-calendar/public-api';
import { Observable, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  //constructor() { }

  /**
   * Fake event data
   * @returns events
   */
  getFakeEvents(): Observable<EventModel[]> {
    const fakeEvents = this.createTestEvents();
    return of(fakeEvents).pipe(
      delay(1000),
      tap(() => console.log),
    );
  }

  /**
   * Fake Workhours data
   * @returns
   */
  getFakeWorkhours(): Observable<WorkHoursModel[]> {
    const maa = new WorkHoursModel(1, '08:00', '17:00');
    const din = new WorkHoursModel(2, '08:00', '17:00');
    const woe = new WorkHoursModel(3, '08:00', '13:00');
    const don = new WorkHoursModel(4, '12:30', '17:00');
    const vri = new WorkHoursModel(5, '08:00', '17:00');

    return of([maa, din, woe, don, vri]).pipe(
      //delay(1000),
      //tap(console.log)
    );
  }

  getFakeContextActions(): Observable<IContextAction[]> {
    return of(this.createTextContextActions()).pipe(
      //delay(200),
      //tap(console.log)
    );
  }

  getFakeMarkedDateTimes(): Observable<IMarkedDateTime[]> {
    const marks: IMarkedDateTime[] = [
      {
        dateTime: DateTime.local().minus({days: 2}),
        color: '#ff8000',
        txtColor: '#ffffff'
      },
      {
        dateTime: DateTime.local().plus({days: 2}),
        color: '#900011',
        txtColor: '#ffffff'
      },
      {
        dateTime: DateTime.local().plus({days: 4}),
        color: 'black',
        txtColor: '#ff8000'
      }
    ];

    return of(marks).pipe(
      delay(500)
    );
  }

  /**
   * Maak test events aan in de kalender. Zorgt ervoor dat je wat dingen kan zien.
   * worden gebaseerd op de huidige  datum, anders zie je niks meer over een paar weken.
   */
  private createTestEvents(): EventModel[] {
    const evts: EventModel[] = [];

    const evt = new EventModel(evts.length, DateTime.local().minus({days: 2}).set({hour: 9, minute: 30}));
    evt.isEditable = false;
    evt.isAllday = false;
    evt.title = 'Testevent 1';
    evt.description = 'uneditable event test';
    evt.endDateTime = evt.startDateTime.plus({hours: 5});
    evts.push(evt);

    const evt2 = new EventModel(evts.length, DateTime.local().minus({days: 1}).set({hour: 16, minute: 45}));
    evt2.isEditable = true;
    evt2.isAllday = false;
    evt2.title = 'Testevent 2';
    evt2.description = '';
    evt2.endDateTime = evt2.startDateTime.plus({hours: 4});
    evts.push(evt2);

    const evt3 = new EventModel('3', DateTime.local().plus({days: 1}).set({hour: 9, minute: 30}));
    evt3.isEditable = true;
    evt3.isAllday = false;
    evt3.title = 'Testevent 3 with a long title';
    evt3.description = '<b>editable</b> event test. <br /> <br /> <i>This event</i> has a string as ID!';
    evt3.endDateTime = evt3.startDateTime.plus({hours: 8});
    evts.push(evt3);

    const evt4 = new EventModel(evts.length, DateTime.local().set({hour: 4, minute:40}).plus({days: 2}));
    evt4.isEditable = true;
    evt4.isAllday = false;
    evt4.endDateTime = evt4.startDateTime.plus({hours: 5, minutes: 30});
    evt4.title = 'Testevent 4 is an event with an extreeemely long title. Just for testing of course.';
    evt4.description = 'editable event allday test - textcolor is "white"';
    evt4.backgroundColor = '#4281a4';
    evt4.textColor = '#00CC00';
    evts.push(evt4);

    const evt51 = new EventModel(evts.length, DateTime.local().minus({days: 1}));
    evt51.isEditable = true;
    evt51.isAllday = true;
    evt51.title = 'Testevent 5.1';
    evt51.description = 'editable event allday test splitted in 3 allday-events (1)';
    evts.push(evt51);

    const evt52 = new EventModel(evts.length, DateTime.local().minus({days: 1}), DateTime.local().plus({days: 1}));
    evt52.isEditable = true;
    evt52.title = 'TestEvent 5.2';
    evt52.description = '<i>editable event allday</i> test meerdere dagen';
    evts.push(evt52);

    const evt53 = new EventModel(evts.length, DateTime.local().minus({days: 1}));
    evt53.isEditable = true;
    evt53.isAllday = true;
    evt53.title = 'TestEvent 5.3';
    evt53.description = '<b>editable event</b> allday test <i>splitted in 3 allday-events (3)</i>';
    evts.push(evt53);

    const evt6 = new EventModel(evts.length, DateTime.local().set({hour: 19, minute: 15}));
    evt6.isAllday = false;
    evt6.isEditable = true;
    evt6.endDateTime = evt6.startDateTime.plus({minutes: 120});
    evt6.title = 'Testevent 6';
    evt6.description = 'Really short editable event with a long description - test';
    evts.push(evt6);

    /**
     * Overlappende events om te testen.
     * */
    const evt7 = new EventModel(evts.length, DateTime.local().set({hour: 17, minute: 15}));
    evt7.isAllday = false;
    evt7.isEditable = true;
    evt7.endDateTime = evt7.startDateTime.plus({hours: 3});
    evt7.title = 'Overlap Event 7';
    evt7.description = 'Overlapping test 7 - Really long editable event with a long description and some overlap with other overlapping test events';
    evt7.backgroundColor = '#1f363d';
    evts.push(evt7);

    const evt81 = new EventModel(evts.length, DateTime.local().set({hour: 10, minute: 30}));
    evt81.isAllday = false;
    evt81.isEditable = true;
    evt81.endDateTime = evt81.startDateTime.plus({minutes: 90});
    evt81.title = 'Overlap Event 8.1';
    evt81.description = 'Overlapping test 8.1, is even lang en gelijktijdig met 8.2';
    evt81.backgroundColor = '#40798c';
    evts.push(evt81);

    const evt82 = new EventModel(evts.length, DateTime.local().set({hour: 10, minute: 30}));
    evt82.isAllday = false;
    evt82.isEditable = true;
    evt82.endDateTime = evt82.startDateTime.plus({minutes: 90});
    evt82.title = 'Overlap Event 8.2';
    evt82.description = 'Overlapping test 8.2, is even lang en gelijktijd met 8.1';
    evt82.backgroundColor = '#ff8000';
    evts.push(evt82);

    const evt83 = new EventModel(evts.length, DateTime.local().set({hour: 10, minute: 15}));
    evt83.isAllday = false;
    evt83.isEditable = true;
    evt83.endDateTime = evt83.startDateTime.plus({minutes: 90});
    evt83.title = 'Overlap Event 8.3';
    evt83.description = 'Overlapping test 8.2, is even lang en gelijktijd met 8.1';
    evt83.backgroundColor = '#00ff00';
    evts.push(evt83);

    const evt84 = new EventModel(evts.length, DateTime.local().set({hour: 10, minute: 45}));
    evt84.isAllday = false;
    evt84.isEditable = true;
    evt84.endDateTime = evt84.startDateTime.plus({minutes: 90});
    evt84.title = 'Overlap Event 8.4';
    evt84.description = 'Overlapping test 8.4';
    evt84.backgroundColor = '#0000ff';
    evts.push(evt84);


    const evt9 = new EventModel(evts.length, DateTime.local().set({hour: 10, minute: 0}));
    evt9.isAllday = false;
    evt9.isEditable = true;
    evt9.endDateTime = evt9.startDateTime.plus({minutes: 150});
    evt9.title = 'Overlap Event 9';
    evt9.description = 'Overlapping test 9 - the ugly one';
    evt9.backgroundColor = '#70a9a1';
    evt9.textColor = '#bb4000';
    evts.push(evt9);

    const evt10 = new EventModel(evts.length, DateTime.local().set({hour: 12, minute: 15}));
    evt10.isAllday = false;
    evt10.isEditable = true;
    evt10.endDateTime = evt10.startDateTime.plus({hours: 3});
    evt10.title = 'Overlap Event 10';
    evt10.description = 'Overlapping test 10';
    evt10.backgroundColor = '#9ec1a3';
    evt10.textColor = '#000000';
    evts.push(evt10);

    /**
     * Events met dezelfde calendarGroupId
     * */
    const evt11 = new EventModel(evts.length, DateTime.local().minus({days: 3}).set({hour: 9, minute: 30}));
    evt11.isAllday = false;
    evt11.isEditable = true;
    evt11.endDateTime = evt11.startDateTime.plus({minutes: 150});
    evt11.title = 'Grouped Event 11';
    evt11.description = 'Grouped event test 11 with calendarGroupId 1.  This event can\'t be clicked';
    evt11.backgroundColor = '#372248';
    evt11.calendarGroupId = 1;
    evt11.textColor = '#fff';
    evt11.isSelectable = false;
    evts.push(evt11);

    const evt12 = new EventModel(evts.length, DateTime.local().minus({days: -3}).set({hour: 12, minute: 30}));
    evt12.isAllday = false;
    evt12.isEditable = true;
    evt12.endDateTime = evt12.startDateTime.plus({hours: 2});
    evt12.title = 'Grouped Event 12';
    evt12.description = 'Grouped event test 12 with calendarGroupId 1';
    evt12.backgroundColor = '#372248';
    evt12.calendarGroupId = 1;
    evt12.textColor = '#ffffff';
    evts.push(evt12);

    const evt13 = new EventModel(evts.length, DateTime.local().minus({days: -4}).set({hour: 9, minute: 30}));
    evt13.isAllday = false;
    evt13.isEditable = true;
    evt13.endDateTime = evt13.startDateTime.plus({hours: 3});
    evt13.title = 'Grouped Event 13';
    evt13.description = 'Grouped event test 13 with calendarGroupId 1. Maar dan een dag eerder';
    evt13.backgroundColor = '#372248';
    evt13.calendarGroupId = 1;
    evt13.textColor = 'white';
    evts.push(evt13);

    /**
     * Event een week later om te kijken of de verspringing nog wel werkt.
     */
    const evt14 = new EventModel(evts.length, DateTime.local().plus({days: 7}).set({hour: 9, minute: 30}));
    evt14.isAllday = true;
    evt14.isEditable = true;
    evt14.endDateTime = evt14.startDateTime.plus({hours: 4});
    evt14.title = 'Grouped Event 14';
    evt14.description = 'Grouped event test 14 with calendarGroupId 1. Is a whole week later.';
    evt14.backgroundColor = '#372248';
    evt14.calendarGroupId = 1;
    evt14.textColor = 'white';
    evts.push(evt14);

    const contextActions = this.createTextContextActions();
    evts.forEach((e:EventModel) => {
      if(e.isEditable){
        e.contextActions = [...contextActions];
      }
    });

    return evts;
  }

  /**
   * Maak context-actions om mee te testen aan. Deze contextactions moeten bij de events gestopt worden.
   * context-acties worden toegvoegd aan events als ze editable zijn. Als er geen acties zijn, zie je niks
   */
  private createTextContextActions(): IContextAction[] {
    return TEST_CONTEXT_ACTIONS;
  }
}


const TEST_CONTEXT_ACTIONS: IContextAction[] = [
  {
    text: 'Delete me',
    contextActionId: 1000,
    hazardLevel: ContextActionTypesEnum.Dangerous,
    isDisabled: false
  },
  {
    text: 'Disabled action',
    contextActionId: 1001,
    hazardLevel: ContextActionTypesEnum.Normal,
    isDisabled: true
  },
  {
    text: 'Neutral',
    contextActionId: 1005,
    hazardLevel: ContextActionTypesEnum.Normal,
    isDisabled: false
  },
  {
    text: '1 day later',
    contextActionId: 1002,
    hazardLevel: ContextActionTypesEnum.Good,
    isDisabled: false
  },
  {
    text: '1 day earlier',
    contextActionId: 1003,
    hazardLevel: ContextActionTypesEnum.Excellent,
    isDisabled: false
  }
  ,
  {
    text: 'Warning example',
    contextActionId: 1004,
    hazardLevel: ContextActionTypesEnum.Warning,
    isDisabled: false
  },
  {
    text: 'starttime 1hr earlier',
    contextActionId: 1005,
    hazardLevel: ContextActionTypesEnum.Normal,
    isDisabled: false
  },
  {
    text: 'eindtime 1hr later',
    contextActionId: 1006,
    hazardLevel: ContextActionTypesEnum.Normal,
    isDisabled: false
  }
];
