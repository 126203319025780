import { DateTime, Interval } from 'luxon';
import { CalendarTypesEnum, PeriodTypeEnum } from '../enums';

export class PeriodModel {

  startDateTime: DateTime;
  endDateTime: DateTime;
  number: number;

  get timespan() {
    return Interval.fromDateTimes(this.startDateTime, this.endDateTime);
  }

  /**
   * Create a new period based on a date in that period and a period-type
   * @param type type of the period.
   * @param date a date in the period.
   */
  public static create(type: PeriodTypeEnum, date: DateTime): PeriodModel {
    const period = new PeriodModel();
    switch(type) {
      case PeriodTypeEnum.Week:
        period.number = date.weekNumber;
        period.startDateTime = date.startOf('week').startOf('day');
        period.endDateTime = date.endOf('week').endOf('day');
        break;
      case PeriodTypeEnum.Fourweeks:
        let weeknr = date.weekNumber;
        if (weeknr == 53) {
          period.number = 14;
          period.startDateTime = date.startOf('week').startOf('day');
          period.endDateTime = date.endOf('week').endOf('day');
        }
        else {
          const periodeCount = 13;
          const weekCount = 52;
          const periodnum: number = Math.ceil((weeknr / weekCount) * periodeCount);
          let startDate: DateTime = date;
          let endDate: DateTime = date;

          while (Math.ceil((weeknr / weekCount) * periodeCount) == periodnum) {
            startDate = startDate.minus({days: 1});
            weeknr = parseInt(startDate.toFormat('W'));
          }
          weeknr = parseInt(date.toFormat('W'));
          while (Math.ceil((weeknr / weekCount) * periodeCount) == periodnum) {
            endDate = endDate.plus({days: 1});
            weeknr = parseInt(endDate.toFormat('W'));
          }
          period.startDateTime = startDate.plus({days: 1}).startOf('day');
          period.endDateTime = endDate.minus({days: 1}).endOf('day');
          period.number = periodnum;
        }
        break;
      case PeriodTypeEnum.Month:
        period.startDateTime = date.startOf('month').startOf('week').startOf('day');
        period.endDateTime = date.endOf('month').endOf('week').endOf('day');
        period.number = date.month;
        break;
      case PeriodTypeEnum.Year:
        period.startDateTime = date.startOf('year').startOf('day');
        period.endDateTime = date.endOf('year').endOf('day');
        period.number = date.year;
        break;
    }
    return period;
  }

  public static createForCalendarType(type: CalendarTypesEnum, date: DateTime): PeriodModel {
    switch(type) {
      case CalendarTypesEnum.List:
      case CalendarTypesEnum.Week:
        return PeriodModel.create(PeriodTypeEnum.Week, date);
      case CalendarTypesEnum.Month:
        return PeriodModel.create(PeriodTypeEnum.Month, date);
      case CalendarTypesEnum.Fourweeks:
        return PeriodModel.create(PeriodTypeEnum.Fourweeks, date);
      case CalendarTypesEnum.Year:
        return PeriodModel.create(PeriodTypeEnum.Year, date);
      default: return PeriodModel.create(PeriodTypeEnum.Week, date);
    }
  }
}
