import { IEventModel, INewEvent } from '../../models/event.model';
import { ListModel } from '../../models/list.model';
import { DayModel } from '../../models/day.model';
import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { NgtrCalendarService } from '../../ngtr-calendar.service';
import { DateTime } from 'luxon';
import { WorkHoursModel } from '../../models/workhours.model';

@Component({
  selector: 'ngtr-calendar-view-list',
  templateUrl: './list.component.html',
  //styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComponent {

  @Input() events: IEventModel[] = [];
  @Input() visibleDays: Set<number> = new Set([1,2,3,4,5,6,7]);
  @Input() workHours: WorkHoursModel[] = [];
  @Input() viewDate: DateTime = DateTime.local();

  @Output() selectEvent: EventEmitter<number|string> = new EventEmitter<number|string>();
  @Output() createEvent: EventEmitter<INewEvent> = new EventEmitter<INewEvent>();
  @Output() daySelected: EventEmitter<DateTime> = new EventEmitter<DateTime>();
  @Output() weekSelected: EventEmitter<number> = new EventEmitter<number>();
  @Output() eventRightClicked: EventEmitter<any> = new EventEmitter<any>();

  listGrid: ListModel;

  constructor(public calendarService: NgtrCalendarService) { }

  ngOnChanges() {
    this.createGrid();
  }

  createGrid() {
    this.listGrid = new ListModel(this.viewDate);
    let checkDate = this.listGrid.startDate;
    while (checkDate <= this.listGrid.endDate) {
      const dag = new DayModel(checkDate);
      dag.isToday = (this.calendarService.isTodayVisible && dag.date.hasSame(DateTime.local(), 'day'));
      const workhours = this.workHours.find((b) => b.dayNumber == dag.date.weekday);
      dag.workHours = workhours ?? new WorkHoursModel(-1, '','');
      dag.events = this.calendarService.getEventsForDay(this.events, dag.date);

      this.listGrid.days.push(dag);
      checkDate = checkDate.plus({days: 1});
    }
  }

  /**
   * new event click
   * @param dt de datum waarop een nieuw event aangemaakt moet worden.
   */
  onNewEventClicked(dt: DateTime) {
    const obj: INewEvent = {
      isAllday: false,
      start: dt,
      end: dt.plus({minutes: 30})
    };
    this.createEvent.emit(obj);
  }
}
