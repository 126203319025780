import { IEventModel, INewEvent } from '../../models/event.model';
import { WeekModel } from '../../models/week.model';
import { MonthModel } from '../../models/month.model';
import { DayModel } from '../../models/day.model';
import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, SimpleChanges } from '@angular/core';
import { NgtrCalendarService } from '../../ngtr-calendar.service';
import { DateTime } from 'luxon';
import { WorkHoursModel } from '../../models/workhours.model';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'ngtr-calendar-view-month',
  templateUrl: './month.component.html',
  //styleUrls: ['./month.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonthComponent {

  @Input() visibleDays: Set<number> = new Set([1,2,3,4,5,6,7]);
  @Input() events: IEventModel[] = [];
  @Input() workHours: WorkHoursModel[] = [];
  @Input() viewDate: DateTime = DateTime.local();

  @Output() selectEvent: EventEmitter<number|string> = new EventEmitter<number|string>();
  @Output() createEvent: EventEmitter<INewEvent> = new EventEmitter<INewEvent>();
  @Output() daySelected: EventEmitter<DateTime> = new EventEmitter<DateTime>();
  @Output() weekSelected: EventEmitter<number> = new EventEmitter<number>();
  @Output() eventRightClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventDragged: EventEmitter<number|string> = new EventEmitter<number|string>();

  monthGrid: MonthModel;
  faIcons = {
    collapse: faChevronUp,
    expand: faChevronDown
  }

  constructor (public calendarService: NgtrCalendarService) { }

  ngOnChanges(changes: SimpleChanges) {
    if(!this.monthGrid) {
      this.createGrid();
      return;
    }

    if(changes?.viewDate)
      this.createGrid();
    else
      this.updateGrid();

  }

  createGrid() {
    this.monthGrid = new MonthModel(this.viewDate);
    let checkDate = this.monthGrid.startDate;
    let week = new WeekModel(checkDate);
    while (checkDate <= this.monthGrid.endDate) {
      const dag = new DayModel(checkDate);
      dag.isToday = (this.calendarService.isTodayVisible && dag.date.hasSame(DateTime.local(), 'day'));
      const workhours = this.workHours.find((b) => b.dayNumber == dag.date.weekday);
      dag.workHours = workhours ?? new WorkHoursModel(-1, '','');
      dag.events = this.calendarService.getEventsForDay(this.events, dag.date);

      week.days.push(dag);
      checkDate = checkDate.plus({days: 1});
      if (week.days.length == 7) {
        this.monthGrid.weeks.push(week);
        week = new WeekModel(checkDate);
      }
    }
  }

  updateGrid() {
    if(!this.monthGrid)
      this.createGrid();

    this.monthGrid.weeks.forEach(week => {
      week.days.forEach((day) => {
        day.events = this.calendarService.getEventsForDay(this.events, day.date);
        const workhours = this.workHours.find((b) => b.dayNumber == day.date.weekday);
        day.workHours = workhours ?? new WorkHoursModel(-1, '','');
      });
    });

  }

  onEventSelected(calID: string|number){
    if(this.calendarService.isBusy)
      return;
    else
      this.selectEvent.emit(calID);

  }

  /**
   * create an event
   * @param dt datetime-obj (Luxon DateTime)
   */
  onCreateEvent(dt: DateTime) {
    if(this.calendarService.isBusy)
      return;

    const obj:  INewEvent = {
      isAllday: true,
      start: dt.startOf('day'),
      end: dt.endOf('day')
    };
    this.createEvent.emit(obj);
  }

  /**
   * day selected
   * @param dtm date name
   */
  onDaycellClicked(dtm: DateTime) {
    const obj: INewEvent = {
      isAllday: false,
      start: dtm,
      end: dtm
    };
    this.createEvent.emit(obj);
  }

  onStopPropagation(evt: MouseEvent) {
    evt.stopPropagation();
  }
}
