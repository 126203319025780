import { Directive, OnDestroy, Input, ElementRef, HostListener } from '@angular/core';
import { TooltipService } from './tooltip.service';
import { IEventModel } from '../models/event.model';
import { NgtrCalendarService } from '../ngtr-calendar.service';

@Directive({ selector: '[ngtrTooltip]' })
export class TooltipDirective implements OnDestroy {

  @Input() tooltipTitle = '';
  @Input() tooltipEvent: IEventModel;
  private id: string;

  /**
   * Constructor van de tooltipdirective.
   * @param tooltipService de service die hoort bij tooltips
   * @param element de referentie naar het element waar de tooltip bijhoort.
   */
  constructor(private tooltipService: TooltipService, private element: ElementRef, private calendarService: NgtrCalendarService) { }

  /**
   * Wat te doen als de muis de host binnen gaat:
   * laat de tooltip zien, in dit geval.
   * @param e het mouse-event
   */
  @HostListener('mouseenter', ['$event']) onMouseEnter(e: MouseEvent): void {
    if (!this.calendarService.isTooltipEnabled)
      return;

    this.id = Math.random() + '';
    this.tooltipService.components.push({
      id: this.id,
      ref: e,
      title: this.tooltipTitle,
      event: this.tooltipEvent,
    });
  }
  /**
   * wat te doen als de muis beweegt
   * de tooltip verplaatsen
   * @param e het mouse-event.
   */
  @HostListener('mousemove', ['$event']) onMouseMove(e: MouseEvent) {
    if (!this.calendarService.isTooltipEnabled)
      return;

    const idx = this.tooltipService.components.findIndex((t) => {
      return t.id == this.id;
    });

    if (idx != -1)
      this.tooltipService.components[idx].ref = e;

  }

  /**
   * wat te doen als de muis de host uitgaat:
   * de tooltip vernietigen.
   */
  @HostListener('mouseleave') onMouseLeave(): void {
    if (!this.calendarService.isTooltipEnabled)
      return;

    this.destroy();
  }

  /**
   * life-cycle hook voor acties zodra de component vernietigt / gesloten wordt.
   */
  ngOnDestroy(): void {
    if (!this.calendarService.isTooltipEnabled)
      return;
    this.destroy();
  }

  /**
   * vernietig alle verwijzingen naar deze tooltip.
   */
  destroy(): void {
    if (!this.calendarService.isTooltipEnabled)
      return;
    try {
      this.tooltipService.components = [];
    }
    catch (error) { this.tooltipService.components = []; }
  }
}
