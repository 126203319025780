/**
 * De Tooltipservice cotnains an array (components) with all tooltips.
 * 1 most of the time
 */

import { Injectable } from '@angular/core';

@Injectable()
export class TooltipService {
  components: any[] = [];
}