import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { CalendarTypesEnum } from '../../enums';
import { IViewChanged } from '../../models/event.model';
import { NgtrCalendarService } from '../../ngtr-calendar.service';

@Component({
  selector: 'ngtr-header-item-calendartype',
  //styleUrls: ['./header.calendartype.component.scss'],
  templateUrl: './header.calendartype.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class CalendarHeaderCalTypeComponent {

  @Input() currentType: CalendarTypesEnum;
  @Input() allowedCalendarTypes: string[] = [];
  @Input() viewDate: DateTime;

  @Output() viewDateChanged: EventEmitter<IViewChanged> = new EventEmitter<IViewChanged>();

  constructor(public calendarService: NgtrCalendarService){}

  onChange() {
    const vdo: IViewChanged = {
      viewDate: this.viewDate,
      newType: this.currentType as CalendarTypesEnum
    };
    this.viewDateChanged.emit(vdo);
  }

  onTodayClicked() {
    const vdo: IViewChanged = {
      viewDate: DateTime.now(),
      newType: this.currentType as CalendarTypesEnum
    };
    this.viewDateChanged.emit(vdo);
  }
}
