import { DateTime } from 'luxon';
import { PeriodTypeEnum } from '../enums';
import { MonthModel } from './month.model';
import { PeriodModel } from './period.model';

export class FourweeksModel extends MonthModel{

  /**
   * properties from @see MaandModel .
   * @param dateInPeriod some date in four-week period
   */
  constructor(dateInPeriod: DateTime) {
    super(dateInPeriod);

    const periode = PeriodModel.create(PeriodTypeEnum.Fourweeks, dateInPeriod);
    this.startDate = periode.startDateTime;
    this.endDate = periode.endDateTime;
  }
}
