import { Component } from '@angular/core';

import { TooltipService } from './tooltip.service';
import { NgtrCalendarService } from '../ngtr-calendar.service';

@Component({
  selector: 'ngtr-tooltip-container',
  template: `
    <div class="tooltip-container" *ngIf="calendarService.isTooltipEnabled">
      <ngtr-tooltip-content
        *ngFor="let tooltip of tooltipService.components"
        [title]="tooltip.title"
        [event]="tooltip.event"
        [ref]="tooltip.ref">
      </ngtr-tooltip-content>
    </div>
  `,
})

export class TooltipContainerComponent {
  constructor(public tooltipService: TooltipService, public calendarService: NgtrCalendarService) { }
}
