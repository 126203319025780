import { DateTime, Interval } from 'luxon';

export class WorkHoursModel {
  dayNumber: number; // monday = 1, tuesday = 2, ... , sunday = 0
  startTime: DateTime;
  endTime: DateTime;

  get timespan(): Interval {
    return Interval.fromDateTimes(this.startTime, this.endTime);
  }

  /**
   * 
   * @param daynum day number (mon = 1, tue = 2, ... sun = 7)
   * @param st starttime
   * @param et endtime
   */
  constructor(daynum: number, st: string | DateTime, et: string | DateTime){
    this.dayNumber = daynum;
    if (typeof st  == 'string') {
      this.startTime = DateTime.fromFormat(`${st}`, 'HH:mm');
    }
    else {
      this.startTime = st;
    }

    if (typeof et == 'string') {
      this.endTime = DateTime.fromFormat(`${et}`, 'HH:mm');
    }
    else {
      this.endTime = et;
    }
  }

  /**
   * check if a time is during workhours
   * @param hr het uur
   * @param mins de minuten.
   */
  isBetween(hr: number, mins: number): boolean {
    let timestamp = DateTime.local();
    timestamp = timestamp.set({hour: hr, minute: mins});
    return this.timespan.contains(timestamp);
  }
}