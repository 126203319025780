import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { DateTime } from 'luxon';
import { CalendarTypesEnum } from './enums';
import { INewEvent, IViewChanged, IEventModel } from './models/event.model';
import { IContextActionResult, IContextHelper } from './models/context.model';
import { NgtrCalendarService } from './ngtr-calendar.service';
import { WorkHoursModel } from './models/workhours.model';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
window.addEventListener('click', e => { contextMenuActive = false; });
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let contextMenuActive: boolean = false;

@Component({
  selector: 'ngtr-calendar',
  templateUrl: 'ngtr-calendar.component.html',
  //styleUrls: ['./ngtr-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgtrCalendarComponent implements OnChanges {
  @Input() calendarTypes: string[] = [];
  @Input() workHours: WorkHoursModel[];
  @Input() events: IEventModel[];
  @Input() currentCalendarType: CalendarTypesEnum = CalendarTypesEnum.Week;
  @Input() viewDate: DateTime = DateTime.local();

  @Output() viewDateChanged: EventEmitter<IViewChanged> = new EventEmitter<IViewChanged>();
  @Output() eventDelete: EventEmitter<number|string> = new EventEmitter<number|string>();
  @Output() eventSelected: EventEmitter<number|string> = new EventEmitter<number|string>();
  @Output() eventCreate: EventEmitter<INewEvent> = new EventEmitter<INewEvent>();
  @Output() contextAction: EventEmitter<IContextActionResult> = new EventEmitter<IContextActionResult>();

  contextDetail: IContextHelper;

  alldayEvents: IEventModel[];
  timedEvents: IEventModel[];
  visibleEvents: IEventModel[];


  constructor(public calendarService: NgtrCalendarService) { }

  ngOnChanges(changes: SimpleChanges) {
    if(!this.events)
      this.events = [];

    if(changes?.events){
      this.alldayEvents = this.events?.filter(e => e.isVisible &&  e.isAllday) ?? [];
      this.timedEvents = this.events?.filter(e =>  e.isVisible && !e.isAllday) ?? [];
      this.visibleEvents = this.events?.filter(e => e.isVisible) ?? [];
    }
    this.workHours = this.workHours ?? [];
  }

  onTodayClicked() {
    const vdo: IViewChanged = {
      viewDate: DateTime.now(),
      newType: this.currentCalendarType
    };
    this.viewDateChanged.emit(vdo);
  }

  /**
   * Weeknumber selected, show that week.
   * @param wn het weeknummer
   */
  onWeekSelected(wn: number) {
    const mom = DateTime.fromFormat(wn + '', 'W');
    this.viewDate = mom;
    this.currentCalendarType = CalendarTypesEnum.Week;
    const obj = {
      newType: CalendarTypesEnum.Week,
      viewDate: mom
    };
    this.viewDateChanged.emit(obj);
  }

  /**
   * Day selected, show only that day in weekview
   * @param {DateTime} dt date obj of day
   */
  onDaySelected(dt: DateTime) {
    this.viewDate = dt;
    this.currentCalendarType = CalendarTypesEnum.Week;
    this.calendarService.visibleDays = new Set([this.viewDate.weekday]);

    const vdo: IViewChanged = {
      viewDate: this.viewDate,
      newType: this.currentCalendarType as CalendarTypesEnum
    };
    this.viewDateChanged.emit(vdo);
  }

  /**
   * month selected, show that month
   * @param dt
   */
  onMonthSelected(dt: DateTime) {
    this.viewDate = dt;
    this.currentCalendarType = CalendarTypesEnum.Month;
    const vdo: IViewChanged = {
      viewDate: this.viewDate,
      newType: this.currentCalendarType
    };
    this.viewDateChanged.emit(vdo);
  }

  /**
   * create new event
   * @param constructorObj: NewEventConstructorObject
   */
  onNewEvent(constructorObj: INewEvent) {
    if(!this.calendarService.isBusy){
      this.eventCreate.emit(constructorObj);
    }
  }

  /**
   * delete an event
   * @param {number|string} evtId het ID van een event.
   */
  onDeleteEvent(evtId: number|string) {
    this.eventDelete.emit(evtId);
  }

  /**
   * er is een event geselecteerd.
   * @param {number|string} evtId het id van het event
   */
  onSelectEvent(evtId: string|number) {
    if(!this.calendarService.isBusy){
      this.eventSelected.emit(evtId);
    }
  }

  /**
   * start contextmenu
   * @param contextEvent het eventcontextmodel
   */
  onRightclickEvent(contextEvent: IContextHelper) {
    contextEvent.event = this.events.find(e => e.id == contextEvent.eventId);
    if (contextEvent.event) {
      this.contextDetail = contextEvent;
    }
  }

  /**
   * context-action chosen or menu dismised
   * @param actionResult action result with calendarID and contextActionID
   */
  onContextClosed(actionResult: IContextActionResult) {
    if (actionResult)  {
      this.contextDetail.actionId = actionResult.contextActionId;
      this.contextAction.emit(actionResult);
    }
    this.contextDetail = null;
  }


  /**
   * Check events for overlap and set stackindex
   * @param events all events
   */
  public checkForOverlappingEvents(events: IEventModel[]): IEventModel[] {
    if(!events) { return[]; }
    events.forEach((e: IEventModel) => { e.stack = 0;});  // reset stackindexes
    for (const event of events.filter(evt => !evt.isAllday)) {

      for (const otherEvt of events.filter(evt => !evt.isAllday && evt.startDateTime.hasSame(event.startDateTime,'day') && evt.id != event.id)) {

        // if(event.isSelectable && !otherEvt.isSelectable) { event.stack++; }  // als dit event selecteer baar is en de ander eniet, deze bovenop

        if(!event.timespan.contains(otherEvt.startDateTime) && !event.timespan.contains(otherEvt.endDateTime)) {
          event.stack += otherEvt.stack;
        }
        else if(event.timespan.contains(otherEvt.startDateTime) && !event.timespan.contains(otherEvt.endDateTime)) {
          event.stack += otherEvt.stack;
        }
      }
    }
    return events;
  }
}
