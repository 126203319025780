import { DateTime } from 'luxon';

export class DateTimeModel {
  dateTime: DateTime;
  color: string;
  txtColor: string;

  get dayNr(): number { return this.dateTime.day; }
  get weekNr(): number { return this.dateTime.weekNumber; }

  private _isToday: boolean = false;
  get isToday(): boolean { return this._isToday; }

  isDisabled: boolean = false;

  constructor(dt: DateTime) {
    this.dateTime = dt;
    this._isToday = dt.hasSame(DateTime.local(), 'day');
    this.txtColor = this._isToday ? '#5e9d69' : this.isDisabled ? '#aaa' : '#555';
  }
}
