import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { CalendarTypesEnum } from '../../enums';
import { IViewChanged } from '../../models/event.model';
import { NgtrCalendarService } from '../../ngtr-calendar.service';

@Component({
  selector: 'ngtr-header-item-viewdate',
  //styleUrls: ['./header.viewdate.component.scss'],
  templateUrl: './header.viewdate.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class CalendarHeaderViewdateComponent {

  @Input() currentType: CalendarTypesEnum;
  @Input() allowedCalendarTypes: string[] = [];
  @Input() viewDate: DateTime;

  @Output() viewDateChanged: EventEmitter<IViewChanged> = new EventEmitter<IViewChanged>();

  allowedDateButtons = {
    prev: true,
    next: true,
    today: true,
  };

  today: DateTime = DateTime.local();
  title: string = 'title';
  subtitle: string = 'subtitle';

  constructor(public calendarService: NgtrCalendarService){}

  ngOnChanges() {
    this.today = DateTime.local();
    this.setButtons(this.viewDate);
    this.setTitles();
  }

  /**
   * Viewdate changed
   * @param val change value for viewdate.
   */
  onChangeClicked(val: number = -1 || 0 || 1) {
    let newDateTime: DateTime;
    if (val == -1 || val == 1) {
      // jump to prev (-1) /next (1) period
      switch (this.currentType) {
        case CalendarTypesEnum.List:                                            // lijst gaat ook per week
        case CalendarTypesEnum.Week:
          if(this.calendarService.visibleDays.size != 1) { newDateTime = this.viewDate.plus({weeks: val}); }
          else {
            newDateTime = this.viewDate.plus({days: val});

            if (this.calendarService.visibleDays.has(1) && val == -1) {
              this.calendarService.visibleDays.delete(1);
              this.calendarService.visibleDays.add(7);
            }
            else if (this.calendarService.visibleDays.has(7) && val == 1) {
              this.calendarService.visibleDays.delete(7);
              this.calendarService.visibleDays.add(1);             }
            else {
              this.calendarService.visibleDays = new Set([newDateTime.weekday]);
            }
          }
          break;
        case CalendarTypesEnum.Month: newDateTime = this.viewDate.plus({months: val}); break;
        case CalendarTypesEnum.Fourweeks:
          const periodnum: number = Math.ceil((this.viewDate.weekNumber / 52) * 13);
          const has53thWeek = val == 1 ? (this.viewDate.endOf('year').weekNumber != 53) : (this.viewDate.startOf('year').weekNumber != 53);
          if(has53thWeek) {
            newDateTime = this.viewDate.plus({weeks: val * 4});
            break;
          }
          else if((periodnum == 1 && val == -1) || (periodnum == 13 && val == 1)) {
            newDateTime = val == -1 ? this.viewDate.startOf('year').minus({days:1}) : this.viewDate.endOf('year');
          }
          else {
            newDateTime = this.viewDate.plus({weeks: val * 4});
          }
          break;
        case CalendarTypesEnum.Year:
          newDateTime = this.viewDate.plus({years: val});
          break;
        default: newDateTime = this.viewDate;
      }
    }
    else {
      // jump to today
      newDateTime = DateTime.local();
    }

    if (newDateTime > this.calendarService.minDateTime && newDateTime < this.calendarService.maxDateTime) {
      // new date should be between min- and max-values
      this.viewDate = newDateTime;
      const vdo: IViewChanged = {
        viewDate: newDateTime,
        newType: this.currentType as CalendarTypesEnum
      };
      this.viewDateChanged.emit(vdo);
    }
    else {
      //console.log(`New date '${newDateTime.toFormat('dd-MM-yyyy HH:mm:ssSS')}' not between MIN_DATE (${this.calendarService.minDateTime.toFormat('dd-MM-yyyy HH:mm:ssSS')}) and MAX_DATE (${this.calendarService.maxDateTime.toFormat('dd-MM-yyyy HH:mm:ssSS')})`);
      return;
    }
  }

  /**
   * set buttons to enabled/disabled based on the new viewdate.
   * @param newViewDate new viewdate
   */
  setButtons(newViewDate: DateTime) {
    let prevDate: DateTime = this.calendarService.minDateTime;
    let nextDate: DateTime = this.calendarService.maxDateTime;

    switch (this.currentType) {
      case CalendarTypesEnum.Week:
      case CalendarTypesEnum.List:
        prevDate = newViewDate.minus({weeks: 1});
        nextDate = newViewDate.plus({weeks: 1});
        break;
      case CalendarTypesEnum.Month:
        prevDate = newViewDate.minus({months: 1});
        nextDate = newViewDate.plus({months: 1});
        break;
      case CalendarTypesEnum.Fourweeks:
        prevDate = newViewDate.minus({weeks: 4});
        nextDate = newViewDate.plus({weeks: 4});
        break;
      case CalendarTypesEnum.Year:
        prevDate = newViewDate.minus({years: 1});
        nextDate = newViewDate.plus({years: 1});
        break;
      default: break;
    }
    this.allowedDateButtons = {
      prev: this.calendarService.minDateTime < prevDate,
      next: this.calendarService.maxDateTime > nextDate,
      today: (DateTime.local() < this.calendarService.maxDateTime && DateTime.local() > this.calendarService.minDateTime)
    };
  }

  /**
   * Set title and subtitle based on selected calendartype and date.
   */
  setTitles() {
    switch (this.currentType) {
      case CalendarTypesEnum.Week:
        this.title = `${this.calendarService.weekFormatNameTxt} ${this.viewDate.toFormat('W')}`;
        this.subtitle = `${this.viewDate.startOf('week').toFormat('dd-MM')} ${this.calendarService.upToForBetweenDatesTxt} ${this.viewDate.endOf('week').toFormat('dd-MM yyyy')}`;
        break;
      case CalendarTypesEnum.Month:
        this.title = `${this.viewDate.toFormat('MMMM')}`;
        this.subtitle = `${this.viewDate.toFormat('yyyy')}`;
        break;
      case CalendarTypesEnum.List:
        this.title = `${this.calendarService.listTitleNameTxt}`;
        this.subtitle = `${this.viewDate.startOf('week').toFormat('ccc dd-MM')} ${this.calendarService.upToForBetweenDatesTxt} ${this.viewDate.endOf('week').toFormat('ccc dd-MM yyyy')}`;
        break;
      case CalendarTypesEnum.Fourweeks:
        this.title = `${this.calendarService.fourweeksFormatNameTxt} ${Math.ceil((this.viewDate.weekNumber / 52) * 13)}`;
        let startOfView = this.viewDate;
        const periodenr = Math.ceil((startOfView.weekNumber / 52) * 13);
        while (Math.ceil((startOfView.weekNumber / 52) * 13) == periodenr) {
          startOfView = startOfView.minus({days: 1});
        }
        startOfView = startOfView.plus({days: 1});
        let endOfView = this.viewDate;
        while (Math.ceil((endOfView.weekNumber / 52) * 13) == periodenr) {
          endOfView = endOfView.plus({days: 1});
        }
        endOfView = endOfView.minus({days: 1});
        this.subtitle = `${startOfView.toFormat('dd-MM')} ${this.calendarService.upToForBetweenDatesTxt} ${endOfView.toFormat('dd-MM yyyy')}`;
        break;
      case CalendarTypesEnum.Year:
        this.title = `${this.viewDate.toFormat('yyyy')}`;
        this.subtitle = `${this.viewDate.toFormat('01/01/yyyy - 31/12/yyyy')}`;
        break;
    }
  }
}
