import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, SimpleChanges } from '@angular/core';
import { IEventModel } from '../../models/event.model';
import { IContextHelper } from '../../models/context.model';
import { NgtrCalendarService } from '../../ngtr-calendar.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'ngtr-agenda-event-block',
  templateUrl: './eventblock.component.html',
  //styleUrls: ['./eventblock.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class EventBlockComponent {

  @Input() event: IEventModel;
  @Input() details = true;
  @Input() bounds: any = undefined;
  @Input() end: DateTime;
  @Input() start: DateTime;

  @Output() eventClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventRightClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventDragged: EventEmitter<number|string> = new EventEmitter<number|string>();
  @Output() eventResized: EventEmitter<number|string> = new EventEmitter<number|string>();

  oldSize: any;
  oldPos: any;
  dragToPos: any;
  draggedOffset = 0;
  resizedOffset = 0;
  calculatedHeight: string = '';
  calculatedTop: string = '';
  calculatedSlotWidth: number = 0;
  isResizing: boolean = false;
  isHovered: boolean = false;


  constructor(public calendarService: NgtrCalendarService) { }

  ngOnChanges(changes: SimpleChanges) {
    if(changes?.event)
      this.calculateSizes();

  }

  onClick(evt: MouseEvent) {
    evt.stopPropagation();
    evt.preventDefault();
    if(this.calendarService.isBusy || !this.event.isSelectable)
      return;
    else {
      if (evt.altKey || evt.shiftKey || evt.ctrlKey || evt.button == 2) {
        if (!this.calendarService.isContextActionsEnabled) { return; }
        // ALT, SHIFT, CTRL + click OR Rightmouseclick
        const obj: IContextHelper = {
          ownerDesc: this.event.title,
          eventId: this.event.id,
          clickEvent: evt,
          event: this.event,
          actionId: 0
        };
        this.eventRightClicked.emit(obj);
      }
      else if(!this.isResizing) {
        this.eventClicked.emit(evt);
      }
      else {
        this.isResizing = false;
      }
    }
  }


  /**
   * calculate distance from top for event
   */
  calculateSizes() {
    this.calculatedSlotWidth = Math.floor((this.bounds.clientWidth -  30) / this.calendarService.visibleDays.size);
    const top = this.event.startDateTime.minute % 60;
    this.calculatedTop = `${top}px`;
    const h = this.event.endDateTime.diff(this.event.startDateTime, 'minutes').toObject().minutes;
    this.calculatedHeight = h < 15 ? '30px' : `${h}px`;
  }
}
