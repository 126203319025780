import { Component, Input, Output, EventEmitter, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { DateTime } from 'luxon';
import { GridModel } from './models/grid.model';
import { IMarkedDateTime } from './models/markeddatetime.model';

@Component({
  selector: 'ngtr-tiny-calendar',
  templateUrl: './ngtr-tiny-calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgtrTinyCalendarComponent implements OnChanges {

  @Input() viewDate: DateTime = DateTime.local();
  @Input() minDate: DateTime;
  @Input() maxDate: DateTime;
  @Input() markedDates: IMarkedDateTime[];
  @Input() today: DateTime;
  @Output() viewDateChanged: EventEmitter<DateTime> = new EventEmitter<DateTime>();

  faIcons = {
    next: faChevronRight,
    prev: faChevronLeft
  }

  grid: GridModel;

  ngOnChanges() {
    this.grid = new GridModel(this.viewDate, this.minDate, this.maxDate, this.today, this.markedDates);
  }

  onDaynumClicked(date: DateTime) {
    this.viewDateChanged.emit(date);
  }
}
