import { DateTime, Info } from 'luxon';
import { DateTimeModel } from './datetime.model';
import { IMarkedDateTime } from './markeddatetime.model';

export class GridModel {
  viewDateTime: DateTime;
  selectedDateTime: DateTime;
  minDateTime: DateTime;
  maxDateTime: DateTime;
  private _today: DateTime;
  markedDates: IMarkedDateTime[];
  weeks: DateTimeModel[][] = [];

  enabled = {
    nextMonth: true,
    prevMonth: true,
    nextYear: true,
    prevYear: true
  };

  get today(): DateTime {
    return this._today;
  }

  get dayNames(): string[] {
    return Info.weekdays('short');
  }

  /**
   * create a new Grid
   * @param viewDate selected date in view
   * @param minDate lowest possible date
   * @param maxDate hightest possible date
   * @param today datetime if current day is not the right day.
   */
  constructor(viewDate: DateTime, minDate?: DateTime, maxDate?: DateTime, today?: DateTime, markedDates?: IMarkedDateTime[]){
    this.viewDateTime = viewDate;
    this.selectedDateTime = viewDate;
    this.markedDates = markedDates ? markedDates : [];
    this.weeks = this.calculateWeeks();
    this.minDateTime = minDate ? minDate : DateTime.fromMillis(0);
    this.maxDateTime = maxDate ? maxDate : DateTime.utc(9999, 1, 1, 0, 0, 0, 0);
    this._today = DateTime.local();
  }

  /**
   * go to next month
   */
  nextMonth() {
    if(!this.enabled.nextMonth) { return; }
    this.viewDateTime = this.viewDateTime.plus({months: 1});
    this.weeks = this.calculateWeeks();
    this.enableButtons();
  }

  /**
   * go to previous month
   */
  prevMonth() {
    if(!this.enabled.prevMonth) { return; }

    this.viewDateTime = this.viewDateTime.minus({months: 1});
    this.weeks = this.calculateWeeks();
    this.enableButtons();
  }

  /**
   * go to next year
   */
  nextYear() {
    if(!this.enabled.nextYear) { return; }
    this.viewDateTime = this.viewDateTime.plus({years: 1});
    this.weeks = this.calculateWeeks();
    this.enableButtons();
  }

  /**
   * go to previous year
   */
  prevYear() {
    if(!this.enabled.prevYear) { return; }
    this.viewDateTime = this.viewDateTime.minus({years: 1});
    this.weeks = this.calculateWeeks();
    this.enableButtons();
  }

  /**
   * decide whicht weeks are visible
   */
  calculateWeeks(): DateTimeModel[][] {
    const weeks: DateTimeModel[][] = [];
    let date = this.viewDateTime.startOf('month').startOf('week');
    let weekDays: DateTimeModel[] = [];
    while (date < this.viewDateTime.endOf('month').endOf('week')) {

      const dtm = new DateTimeModel(date);
      dtm.isDisabled = !date.hasSame(this.viewDateTime, 'month');
      dtm.txtColor = dtm.isToday ? '#5e9d69' : dtm.isDisabled ? '#aaa' : '#333';

      if(this.markedDates) {
        const md = this.markedDates.find(m => m.dateTime.hasSame(date, 'day'));
        dtm.color = md ? md.color : 'transparent';
        if(md?.txtColor) {
          dtm.txtColor = md.txtColor;
        }
      }

      weekDays.push(dtm);
      if(weekDays.length == 7) {
        weeks.push(weekDays);
        weekDays = [];
      }
      date = date.plus({days: 1});
    }
    return weeks;
  }

  enableButtons() {
    this.enabled = {
      nextMonth: this.viewDateTime.plus({months: 1}) > this.maxDateTime ? false : true,
      prevMonth: this.viewDateTime.minus({months: 1}) < this.minDateTime ? false : true,
      nextYear: this.viewDateTime.plus({years: 1}) > this.maxDateTime ? false : true,
      prevYear: this.viewDateTime.minus({years: 1}) < this.minDateTime ? false : true
    };
  }
}
