import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { IViewChanged } from '../../models/event.model';
import { NgtrCalendarService } from '../../ngtr-calendar.service';

@Component({
  selector: 'ngtr-header-item-visibledays',
  //styleUrls: ['./header.visibledays.component.scss'],
  templateUrl: './header.visibledays.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarHeaderVisibleDaysComponent {

  @Input() currentType: string;
  @Input() viewDate: DateTime;

  @Output() viewDateChanged: EventEmitter<IViewChanged> = new EventEmitter<IViewChanged>();

  dayNames: string[] = ['', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  completeDayNums: number[] = [1, 2, 3, 4, 5, 6, 7];

  constructor(public calendarService: NgtrCalendarService) { }

  ngOnInit() {
    this.dayNames = ['']; // eerste slot moet leeg zijn
    this.completeDayNums.forEach((dayNum) => {
      this.dayNames.push(DateTime.local().set({weekday: dayNum}).toFormat('ccc'));
    });
  }

  /**
   * De zichtbare dagen worden aangepast.
   * @param day de dag die wordt gewijzigd.
   */
  onVisibleDaysChanged(day: number) {
    const visibleDays: Set<number> = this.calendarService.visibleDays;
    if (visibleDays.has(day)) {
      visibleDays.delete(day);
    }
    else {
      visibleDays.add(day);
    }

    if (visibleDays.size == 0) {
      this.calendarService.visibleDays = new Set([1, 2, 3, 4, 5, 6, 7]);
    }
    else {
      this.calendarService.visibleDays = new Set([...visibleDays]);
    }
  }
}
