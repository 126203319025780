import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IContextActionResult, IContextHelper } from '../models/context.model';
import { NgtrCalendarService } from './../ngtr-calendar.service';

@Component({
  selector: 'ngtr-contextmenu',
  templateUrl: './contextmenu.component.html',
  //styleUrls: ['./contextmenu.component.scss']
})
export class ContextmenuComponent implements OnInit {

  @Input() context: IContextHelper;
  @Input() noActionsMessage = 'No actions';

  @Output() closeContext: EventEmitter<any> = new EventEmitter<any>();
  @Output() contextAction: EventEmitter<IContextActionResult> = new EventEmitter<IContextActionResult>();

  top = '';
  left = '';

  get locationCorrection() { return 20; }

  constructor(public calendarService: NgtrCalendarService) { }
  ngOnInit() {
    this.top = this.context?.clickEvent?.pageY -  this.locationCorrection + 'px';
    this.left = this.context?.clickEvent?.pageX - this.locationCorrection + 'px';
  }

  /**
   * context-action clicked
   * @param caId context actionID
   */
  onExecute(caId: number) {
    const rslt: IContextActionResult = {
      eventId: this.context.eventId,
      contextActionId: caId
    };
    this.contextAction.emit(rslt);
  }
}
