import { enableProdMode } from '@angular/core';                             // maakt het compileren van de site langdurig, maar resultaat is kleiner.
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'; // nodig voor het opstarten van de app.
import { DateTime } from 'luxon';

import { AppModule } from './app/app.module';                               // beginpunt van de app
import { environment } from './environments/environment';                   // controleren welke environment actief is (debug heeft production als false).

/**
 * Als de production-boolean true is, dan moet de app in production-modus draaien.
 * afhankelijk van de bouw-modus wordt de  environment.production boolean geset.
 */
if (environment.production) {
  enableProdMode();
}
else {
  console.log(`${DateTime.local().toFormat('dd-MM-yyyy HH:mm:ss')}: De appcomponent is in debug-modus gebouwd`);
}

/**
 * start de app op.
 */
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));