import { DateTime, Interval } from 'luxon';
import { CalendarTypesEnum } from './../enums';
import { IContextAction } from './context.model';

export interface IEventModel {
  id: number|string;
  title: string;
  description: string;
  backgroundColor: string;
  borderColor: string;
  textColor: string;
  stack: number;
  isVisible: boolean;
  isEditable: boolean;
  isSelectable: boolean;
  startDateTime: DateTime;
  endDateTime: DateTime;

  // arrays
  contextActions?: IContextAction[];

  // get & set
  isAllday: boolean;
  // startSlot: string;
  // height: string;
  timespan: Interval;
}

export class EventModel implements IEventModel {
  /** title event */ title = '';
  /** description event */ description = '';
  /** context actions even */ contextActions: IContextAction[] = [];
  /** color of event */ backgroundColor = '#003153';
  /** color of border*/ borderColor = '#000000';
  /** text color */     textColor = '#FFFFFF';
  /** id of event */    id: number|string;
  /** index for overlap order*/ stack: number = 0;
  /** is this event visible? */ isVisible: boolean = true;
  /** is this event editable? */ isEditable: boolean = true;
  /** is this event selectable? */ isSelectable: boolean = true;
  /** id of group of events */ calendarGroupId: number = 0;

  startDateTime: DateTime;
  endDateTime: DateTime;

  /** interval of this event */
  get timespan(): Interval {
    return Interval.fromDateTimes(this.startDateTime, this.endDateTime);
  }

  private _isAllday: boolean = false;
  get isAllday(): boolean {
    if(this.startDateTime.hasSame(this.endDateTime, 'day'))
      return this._isAllday;
    return true;
  }
  set isAllday(val: boolean) {
    this._isAllday = val;
  }

  // get startSlot(): string {
  //   return `${this.startDateTime.hour}:${this.startDateTime.minute < 30 ? '00' : '30'}`;
  // }
  // get height(): string {
  //   const height = this.endDateTime.diff(this.startDateTime, 'minutes').toObject().minutes || 0;
  //   return `${height < 15 ? 30 : height}px`;
  // }

  /**
   * New Event
   * @param id unique identifier for event.
   * @param startDateTime start-datetime voor het event.
   * @param endDateTime eind-datetime voor het event
   */
  constructor(id: number|string, startDateTime: DateTime, endDateTime?: DateTime, allday?: boolean|undefined) {
    this.id = id;
    this.startDateTime = startDateTime ?? DateTime.local();
    this.endDateTime = endDateTime ?? this.startDateTime.plus({hours: 1});
    this.isAllday = allday != undefined ? allday : !this.startDateTime.hasSame(this.endDateTime, 'day');
  }

  /**
   * event from json
   * @param jsonstr event as json-string
   */
  public static fromJSON(jsonstr: any): EventModel {
    let json = (jsonstr) as IEventModel;
    if (json.id == undefined && typeof(jsonstr) == 'string')
      json = JSON.parse(jsonstr) as EventModel;

    const obj: EventModel = new EventModel(json.id, json.startDateTime);
    obj.id = json.id;
    obj.isAllday = json.isAllday;
    obj.backgroundColor = json.backgroundColor;
    obj.startDateTime = json.startDateTime;
    obj.endDateTime = json.endDateTime;
    obj.isEditable = json.isEditable;
    return obj;
  }
}

/**
 * new event construction data.
 * used as result for create-event
 */
export interface INewEvent {
  start: DateTime;
  end: DateTime;
  isAllday: boolean;
}

/**
 * view change result data.
 * - new type: Week | Month | Fourweeks | Day | Year
 * - datum as DateTime() [luxon]
 */
export interface IViewChanged {
  newType: CalendarTypesEnum;
  viewDate: DateTime;
}
