import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'luxonstring'
})
export class LuxonDateTimeStringPipe implements PipeTransform {
  transform(val: DateTime, format: string = 'dd-MM-yyyy'): string {
    return val ? val.toFormat(format) : '';
  }
}
