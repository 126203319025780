import { DateTime, Interval } from 'luxon';
import { MonthModel } from './month.model';

export class YearModel {
  events: any[] = [];
  monthRows: MonthModel[][] = [];
  startDate: DateTime;
  endDate: DateTime;
  width: number = 4;
  height: number = 3;

  get currentMonth(): number {
    return DateTime.local().month;
  }
  get currentYear(): number {
    return DateTime.local().year;
  }
  get timespan(): Interval {
    return Interval.fromDateTimes(this.startDate, this.endDate);
  }


  /**
   * @param dateInYear some date in wished year.
   */
  constructor(dateInYear: DateTime) {
    this.startDate = dateInYear.startOf('year').startOf('day');
    this.endDate = dateInYear.endOf('year').endOf('day');
    let checkDate = this.startDate;
    let monthArr: MonthModel[] = [];
    while(checkDate < this.endDate){
      monthArr.push(new MonthModel(checkDate, true));
      checkDate = checkDate.plus({months: 1});
      if(monthArr.length == this.width) {
        this.monthRows.push(monthArr);
        monthArr = [];
      }
    }
    //console.log(this);
  }

  get dateArray(): DateTime[] {
    const arr: DateTime[] = [];
    let date = this.startDate;
    while (date <= this.endDate)  {
      arr.push(date);
      date = date.plus({days: 1});
    }
    return arr;
  }
}
