import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { EventModel, IEventModel } from './models/event.model';

@Injectable({
  providedIn: 'root'
})

export class NgtrCalendarService {


  //#region DateTime options

  /**
   * Min DateTime of calendar
   */
  private _minDateTime: DateTime = DateTime.fromMillis(0); // 01-01-1970 00:00:00.00
  /**
   * Get current minimum DateTime
   */
  get minDateTime(): DateTime{ return this._minDateTime; }
  /**
   * set minumum DateTime.
   * @param val: minimal date as DateTime obj.
   */
  set minDateTime(val: DateTime) { this._minDateTime = val; }

  /**
   * MAX DateTime of calendar
   */
  private _maxDateTime: DateTime = DateTime.fromSeconds(4294967295); // 01-feb 2107
  /**
   * get max DateTime
   */
  get maxDateTime(): DateTime{ return this._maxDateTime; }
  /**
   * set max DateTime
   * @param val: max date as DateTime obj.
   */
  set maxDateTime(val: DateTime) { this._maxDateTime = val; }

  //#endregion

  //#region Numeric options

  // row height: 60 --> 60px high.
  private _rowHeight = 60;
  get rowHeight(): number{ return this._rowHeight; }

  private _maxHeight: string = '1000px';
  get maxHeight(): string {
    return this._maxHeight;
  }
  set maxHeight(val: string) {
    this._maxHeight = val;
  }

  public visibleDays: Set<number> = new Set([1, 2, 3, 4, 5, 6, 7]);
  public visibleHours: Set<number> = new Set([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,12,13,14,15,16,17,18,19,20,21,22,23]);

  private _alldayEventHeight = 51;
  get alldayEventHeight(): number{ return this._alldayEventHeight; }

  //#endregion

  //#region Boolean Options

  // is calendar busy with something
  isBusy: boolean = false;

  // show current day
  private _showTodayInView = true;
  get isTodayVisible(): boolean { return this._showTodayInView; }
  set isTodayVisible(val) { this._showTodayInView = val; }

  // allday visible?
  private _isAlldayExpandedOnStart = false;
  get isAlldayExpandedOnStart(): boolean { return this._isAlldayExpandedOnStart; }
  set isAlldayExpandedOnStart(val: boolean) { this._isAlldayExpandedOnStart = val; }

  // Context menu allowed
  private _contextActionsEnabled = true;
  get isContextActionsEnabled(): boolean { return this._contextActionsEnabled; }
  set isContextActionsEnabled(val) { this._contextActionsEnabled = val; }

  // Tooltips enabled?
  private _tooltipEnabled = true;
  get isTooltipEnabled(): boolean { return this._tooltipEnabled; }
  set isTooltipEnabled(val) { this._tooltipEnabled = val; }

  //#endregion

  //#region Text Options

  public defaultDateFormat: string = 'd MMM';

  // text for go-to-today-button
  private _todayTxt = 'Today';
  get todayButtonTxt(): string { return this._todayTxt; }
  set todayButtonTxt(val: string) { this._todayTxt = val; }

  // name for week-period
  private _weekFormatNameTxt = 'Week';
  get weekFormatNameTxt(): string { return this._weekFormatNameTxt; }
  set weekFormatNameTxt(val: string) { this._weekFormatNameTxt = val; }

  // name of fourweeks-period
  private _fourweeksFormatNameTxt = 'Period';
  get fourweeksFormatNameTxt(): string { return this._fourweeksFormatNameTxt; }
  set fourweeksFormatNameTxt(val: string) { this._fourweeksFormatNameTxt = val; }

  // name for the 'up to' between two dates (ex: 1-dec up to 12-dec)
  private _upToForBetweenDatesTxt = 'up to';
  get upToForBetweenDatesTxt(): string { return this._upToForBetweenDatesTxt; }
  set upToForBetweenDatesTxt(val: string) { this._upToForBetweenDatesTxt = val; }

  // name for selecting the year-view
  private _yearSelectionNameTxt = 'Year';
  get yearSelectionNameTxt(): string { return this._yearSelectionNameTxt; }
  set yearSelectionNameTxt(val: string) { this._yearSelectionNameTxt = val; }

  // name for selecting the fourweeks-view
  private _fourweeksSelectionNameTxt = 'Fourweeks';
  get fourweeksSelectionNameTxt(): string { return this._fourweeksSelectionNameTxt; }
  set fourweeksSelectionNameTxt(val: string) { this._fourweeksSelectionNameTxt = val; }

  // name for selecting the month view
  private _monthSelectionNameTxt = 'Month';
  get monthSelectionNameTxt(): string { return this._monthSelectionNameTxt; }
  set monthSelectionNameTxt(val: string) { this._monthSelectionNameTxt = val; }

  // name for selecting the week view
  private _weekSelectionNameTxt = 'Week';
  get weekSelectionNameTxt(): string { return this._weekSelectionNameTxt; }
  set weekSelectionNameTxt(val: string) { this._weekSelectionNameTxt = val; }

  // name for selecting a list view
  private _listSelectionNameTxt = 'List';
  get listSelectionNameTxt(): string { return this._listSelectionNameTxt; }
  set listSelectionNameTxt(val: string) { this._listSelectionNameTxt = val; }

  // name of listview
  private _listTitleNameTxt = 'List view';
  get listTitleNameTxt(): string { return this._listTitleNameTxt; }
  set listTitleNameTxt(val: string) { this._listTitleNameTxt = val; }

  // text when there are no context actions
  private _noContextActionsMessage = 'No Actions';
  get noContextActionsMessage(): string { return this._noContextActionsMessage; }
  set noContextActionsMessage(val: string) { this._noContextActionsMessage = val; }

  // text when there are no events
  private _noEventsMessage = 'No events.';
  get noEventsMessage(): string { return this._noEventsMessage; }
  set noEventsMessage(txt: string) { this._noEventsMessage = txt; }

  // text for multiple events
  private _multipleEventsTxt = 'agenda-items';
  get multipleEventsTxt(): string { return this._multipleEventsTxt; }
  set multipleEventsTxt(txt: string) { this._multipleEventsTxt = txt; }

  // text for a single event
  private _oneEventTxt = 'agenda-item';
  get oneEventTxt(): string { return this._oneEventTxt; }
  set oneEventTxt(txt: string) { this._oneEventTxt = txt; }

  //#endregion

  //#region Functions Options

  /**
   * Filter events for a day.
   * @param events de events
   * @param date De gewenste datum
   */
  public getEventsForDay(events: IEventModel[], date: DateTime): IEventModel[] {
    return events.filter(e => e.isVisible).filter(
      (e: EventModel) =>
        e.startDateTime.hasSame(date, 'day') ||
        e.endDateTime.hasSame(date, 'day') ||
        e.timespan.contains(date)
    );
  }

  /**
   * @deprecated use @see setOptions()
   * @param options
   */
  public set(options: INgtrCalendarOptions) {this.setOptions(options); }

  /**
   * use a @see NgtrCalendarSettings settings object in the service.
   * @param options
   */
  public setOptions(options: INgtrCalendarOptions){
    this.defaultDateFormat = options.defaultDateFormat ?? this.defaultDateFormat;
    this.todayButtonTxt = options.todayButtonTxt ?? this.todayButtonTxt;
    this.fourweeksFormatNameTxt = options.fourweeksFormatNameTxt ?? this.fourweeksFormatNameTxt;
    this.fourweeksSelectionNameTxt = options.fourweeksSelectionNameTxt ?? this.fourweeksSelectionNameTxt;
    this.weekFormatNameTxt = options.weekFormatNameTxt ?? this._weekFormatNameTxt;
    this.weekSelectionNameTxt = options.weekSelectionNameTxt ?? this._weekSelectionNameTxt;
    this.yearSelectionNameTxt = options.yearSelectionNameTxt ?? this.yearSelectionNameTxt;
    this.monthSelectionNameTxt = options.monthSelectionNameTxt ?? this.monthSelectionNameTxt;
    this.listTitleNameTxt = options.listTitleNameTxt ?? this.listTitleNameTxt;
    this.listSelectionNameTxt = options.listSelectionNameTxt ?? this.listSelectionNameTxt;
    this.noContextActionsMessage = options.noContextActionsMessage ?? this.noContextActionsMessage;
    this.noEventsMessage = options.noEventsMessage ?? this.noEventsMessage;
    this.multipleEventsTxt = options.multipleEventsTxt ?? this.multipleEventsTxt;
    this.oneEventTxt = options.oneEventTxt ?? this.oneEventTxt;
    this.upToForBetweenDatesTxt = options.upToForBetweenDatesTxt ?? this.upToForBetweenDatesTxt;
    this.visibleDays = (options.visibleDays && options.visibleDays.length > 0) ? new Set(options.visibleDays) : new Set(this.visibleDays);
    this.visibleHours = (options.visibleHours && options.visibleHours.length > 0) ? new Set(options.visibleHours) : new Set(this.visibleHours);
    this.isTooltipEnabled = (typeof options.tooltipEnabled == 'boolean') ? options.tooltipEnabled : this.isTooltipEnabled;
    this.isContextActionsEnabled = (typeof options.contextActionsEnabled == 'boolean') ? options.contextActionsEnabled : this.isContextActionsEnabled;
    this.isAlldayExpandedOnStart = (typeof options.alldayExpanded == 'boolean') ? options.alldayExpanded : this.isAlldayExpandedOnStart;
    this.isTodayVisible = (typeof options.todayInView == 'boolean') ? options.todayInView : this.isTodayVisible;
    this.maxHeight = options.maxHeight ?? this.maxHeight;
    this.minDateTime = options.minDateTime ?? this.minDateTime;
    this.maxDateTime = options.maxDateTime ?? this.maxDateTime;
  }
}

//#endregion



/**
 * Settings for the calendar.
 */
export interface INgtrCalendarOptions {
  defaultDateFormat?: string | undefined;
  todayButtonTxt?: string | undefined;
  fourweeksFormatNameTxt?: string | undefined;
  fourweeksSelectionNameTxt?: string | undefined;
  weekFormatNameTxt?: string | undefined;
  weekSelectionNameTxt?: string | undefined;
  yearSelectionNameTxt?: string | undefined;
  monthSelectionNameTxt?: string | undefined;
  listTitleNameTxt?: string | undefined;
  listSelectionNameTxt?: string | undefined;
  noContextActionsMessage?: string | undefined;
  noEventsMessage?: string | undefined;
  multipleEventsTxt?: string | undefined;
  oneEventTxt?: string | undefined;
  upToForBetweenDatesTxt?: string | undefined;
  visibleDays?: number[] | undefined;
  visibleHours?: number[] | undefined;
  tooltipEnabled?: boolean | undefined;
  contextActionsEnabled?: boolean | undefined;
  tooltipsEnabled?: boolean | undefined;
  todayInView?: boolean | undefined;
  alldayExpanded?: boolean | undefined;
  maxHeight?: string | undefined;
  minDateTime?: DateTime | undefined;
  maxDateTime?: DateTime | undefined;
}
