import { DateTime, Interval } from 'luxon';
import { IEventModel } from './event.model';
import { WorkHoursModel } from './workhours.model';

export class DayModel {
  date: DateTime;
  isActive = true;
  isToday = true;
  events: IEventModel[] = [];
  workHours: WorkHoursModel;
  isExpanded: boolean = false;

  get visibleEvents(): IEventModel[] {
    return this.events.filter(e => e.isVisible);
  }

  get visibleAlldayEventsCount(): number {
    return this.events.filter(e => e.isAllday && e.isVisible).length;
  }

  get timespan(): Interval {
    return Interval.fromDateTimes(this.date.startOf('day'), this.date.endOf('day'));
  }

  /**
   * create a new day model
   * @param datetime de datum voor dit dagmodel
   */
  constructor(datetime: DateTime) {
    this.date = datetime;
    this.workHours = new WorkHoursModel(this.date.weekday, '00:00','00:00');
  }

}
