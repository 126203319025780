import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, SimpleChanges } from '@angular/core';
import { IEventModel, INewEvent } from '../../models/event.model';
import { WeekModel } from '../../models/week.model';
import { DayModel } from '../../models/day.model';
import { NgtrCalendarService } from '../../ngtr-calendar.service';
import { DateTime } from 'luxon';
import { WorkHoursModel } from '../../models/workhours.model';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { IWeekViewRowCell, WeekViewRowModel } from '../../models/weekviewrow.model';

@Component({
  selector: 'ngtr-calendar-view-week',
  templateUrl: './week.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
  //styleUrls: ['./week.component.scss'],
})
export class WeekComponent {

  @Input() viewDate: DateTime = DateTime.local();
  @Input() visibleDays: Set<number> = new Set([1, 2, 3, 4, 5, 6, 7]);
  @Input() visibleHours: Set<number> = new Set([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]);
  @Input() timedEvents: IEventModel[] = [];
  @Input() alldayEvents: IEventModel[] = [];
  @Input() workHours: WorkHoursModel[] = [];

  @Output() createEvent: EventEmitter<INewEvent> = new EventEmitter<INewEvent>();
  @Output() selectEvent: EventEmitter<number|string> = new EventEmitter<number|string>();
  @Output() eventResized: EventEmitter<number|string> = new EventEmitter<number|string>();
  @Output() eventDragged: EventEmitter<number|string> = new EventEmitter<number|string>();
  @Output() eventRightClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteEvent: EventEmitter<number|string> = new EventEmitter<number|string>();

  weekGrid: WeekModel;
  isAlldayVisible: boolean = false;
  faIcons = {
    collapse: faChevronUp,
    expand: faChevronDown
  }

  constructor(public calendarService: NgtrCalendarService) { }

  ngOnInit() {
    this.isAlldayVisible = this.calendarService.isAlldayExpandedOnStart;
    this.weekGrid = this.createGrid();
  }

  ngOnChanges(changes:SimpleChanges) {
    if(!this.weekGrid){
      this.weekGrid = this.createGrid();
      return;
    }

    if(changes?.visibleDays)
      this.weekGrid.weekViewRows.forEach(r => r.cells.forEach(c => c.isVisible = this.visibleDays.has(c.dayNum)));
    else if(changes?.viewDate)
      this.weekGrid = this.createGrid();
    else if(changes?.timedEvents || changes?.alldayEvents)
      this.weekGrid = this.createGrid();
  }


  onToggleAllday(event: MouseEvent) {
    event.stopPropagation();
    this.isAlldayVisible = !this.isAlldayVisible;
  }

  createGrid(): WeekModel {
    const wg = new WeekModel(this.viewDate);
    //this.alldayEvents.forEach(evt => evt.isVisible = wg.timespan.contains(evt.startDateTime) || wg.timespan.contains(evt.endDateTime));
    //this.timedEvents.forEach(evt => evt.isVisible = wg.timespan.contains(evt.startDateTime));
    // in weekview bevat days alleen maar de allday-events
    wg.days = wg.dateArray.map(date => {
      const day = new DayModel(date);
      day.isToday = this.calendarService.isTodayVisible ? day.date.hasSame(DateTime.local(), 'day') : false;
      day.workHours = this.workHours.find((b) => b.dayNumber == day.date.weekday) ?? new WorkHoursModel(-1, '','');
      day.events = this.calendarService.getEventsForDay(this.alldayEvents, day.date) ?? [];
      return day;
    });

    this.calendarService.visibleHours.forEach(hr => {
      const rows = wg.days.map(d => {
        const cell: IWeekViewRowCell = {
          dayNum: d.date.weekday,
          isVisible: this.visibleDays.has(d.date.weekday),
          events: this.timedEvents.filter(e => e.isVisible && wg.timespan.contains(e.startDateTime) && e.startDateTime.hour == hr && e.startDateTime.weekday == d.date.weekday),
          isToday: d.isToday
        };
        return cell;
      });
      wg.weekViewRows.push(new WeekViewRowModel(hr, rows));
    });
    setTimeout(() => { this.weekGrid.RESIZER_FOR_SCROLLING = 0; }, 1);
    return wg;
  }

  /**
   * event selected
   * @param me mouse-event
   * @param event event
   */
  onEventSelected(me: MouseEvent, event: IEventModel) {
    if(this.calendarService.isBusy)
      me.stopPropagation();
    else
      this.selectEvent.emit(event.id);

  }

  /**
   * dragging/resizing
   * @param event  event.
   * @param pxChange change
   * @param type type of resizing
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onEventChanged(calID: string|number, type: 'drag' | 'resize') {
    // switch (type) {
    //   case EventChangeTypesEnum.Dragging:
    //     this.eventDragged.emit(calID);
    //     this.updateGrid();
    //     break;
    //   case EventChangeTypesEnum.Resizing:
    //     this.eventResized.emit(calID);
    //     break;
    //   default: break;
    // }
  }

  /**
   * @param evt het event
   */
  onEventRightClicked(evt: MouseEvent) {
    evt.stopPropagation();
    this.eventRightClicked.emit(evt);
  }

  /**
   * @param daynum day number (mo = 1, tue = 2, etc)
   * @param hour full-hour number
   * @param min minute timestamp
   */
  onTimeslotSelected(daynum: number, hour: number, min: number) {
    const dt = this.viewDate.set({weekday: daynum});
    const obj: INewEvent = {
      isAllday: hour == -1,
      start: hour == -1 ? dt.set({hour: 0, minute: 0, second: 0}) : dt.set({hour: hour, minute: min, second: 0}) ,
      end: hour == -1 ? dt.set({hour: 23, minute: 59, second: 59}) : dt.set({hour: hour, minute: min, second: 0}).plus({minutes: 30})
    };
    this.createEvent.emit(obj);
  }

  /**
   * does a slot contain workhours?
   * @param day het model van de dag
   * @param hr tijdstip-uur
   * @param min tijdstip-min
   */
  hasWorkhours(day: DayModel, hr: number, min: number): boolean {
    if (day.workHours)
      return day.workHours.isBetween(hr, min);

    return false;
  }

  /**
   * day name clicked
   * @param daynum het dagnummer
   */
  onDaySelected(daynum: number) {
    if(this.calendarService.visibleDays.size == 1)
      this.calendarService.visibleDays = new Set([1, 2, 3, 4, 5, 6, 7]);
    else
      this.calendarService.visibleDays = new Set([daynum]);

  }
}
