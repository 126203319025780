import { Component, Input, HostListener } from '@angular/core';
import { IEventModel } from '../models/event.model';

@Component({
  selector: 'ngtr-tooltip-content',
  //styleUrls: ['./tooltip.component.scss'],
  templateUrl: './tooltip.component.html'
})
export class TooltipContentComponent {

  @Input() title: string;         // tooltip title
  @Input() ref: any;              // mouse-reference
  @Input() event: IEventModel; // event of tooltip

  top = '';
  left = '';
  private waitingTime = 150;

  ngOnChanges(): void {
    // position based on `ref`
    setTimeout(() => {
      this.top = this.ref.pageY + 15 + 'px';
      this.left = this.ref.pageX + 15 + 'px';
    },         this.waitingTime);
  }

  /**
   * screen size changess
   */
  @HostListener('window:resize')
  onWindowResize(): void {
    // update position based on `ref`
    setTimeout(() => {
      this.top = this.ref.pageY + 15 + 'px';
      this.left = this.ref.pageX + 15 + 'px';
    },         this.waitingTime);
  }
}
