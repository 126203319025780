import { EventModel, IEventModel, INewEvent } from '../../models/event.model';
import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, SimpleChanges } from '@angular/core';
import { NgtrCalendarService } from '../../ngtr-calendar.service';
import { DateTime } from 'luxon';
import { YearModel as YearModel } from '../../models/year.model';
import { WorkHoursModel } from '../../models/workhours.model';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'ngtr-calendar-view-year',
  templateUrl: './year.component.html',
  //styleUrls: ['./year.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YearComponent {

  @Input() events: IEventModel[] = [];
  @Input() workHours: WorkHoursModel[] = [];
  @Input() viewDate: DateTime = DateTime.local();

  @Output() selectEvent: EventEmitter<string|number> = new EventEmitter<string|number>();
  @Output() createEvent: EventEmitter<INewEvent> = new EventEmitter<INewEvent>();
  @Output() monthSelected: EventEmitter<DateTime> = new EventEmitter<DateTime>();
  @Output() eventRightClicked: EventEmitter<any> = new EventEmitter<any>();

  yearGrid: YearModel;
  history = {
    viewDate: DateTime.local(),
    eventCount: this.events.length
  };
  faIcons = {
    collapse: faChevronUp,
    expand: faChevronDown
  }

  constructor(public calendarService: NgtrCalendarService) { }

  ngOnChanges(changes: SimpleChanges) {
    if(!this.yearGrid) {
      this.createGrid();
      return;
    }

    if(changes.viewDate)
      this.createGrid();
    else
      this.updateGrid();

  }

  createGrid() {
    this.history = {
      viewDate: this.viewDate,
      eventCount:this.events.length
    };
    this.yearGrid = new YearModel(this.viewDate);
    for(const maandRij of this.yearGrid.monthRows){
      maandRij.forEach((maand) => {
        const events = this.events.filter(e => e.startDateTime.hasSame(maand.startDate, 'month'));
        maand.events = this.sortEvents(events);
      });
    }
  }
  updateGrid() {
    if(!this.yearGrid)
      this.createGrid();

    this.history = {
      viewDate: this.viewDate,
      eventCount: this.events.length
    };
    for(const months of this.yearGrid.monthRows) {
      months.forEach((m) => m.events = this.events.filter(e => e.startDateTime.hasSame(m.startDate, 'month')));
    }
  }

  /**
   * create an event
   * @param dt date obj (Luxon DateTime)
   */
  onCreateEvent(dt: DateTime) {
    const obj: INewEvent = {
      isAllday: false,
      start: dt,
      end: dt.plus({minutes: 30})
    };
    this.createEvent.emit(obj);
    this.createEvent.emit(obj);
  }

  onStopPropagation(evt: MouseEvent) {
    evt.stopPropagation();
  }

  /**
   * Sort events based on their allday/notallday and start/end time
   * - compare dates, then
   * - compare alldays, then
   * - compare starttimes, then
   * - compare endtimes
   * @param events All events on a day
   * @returns sorted events aray
   */
  public sortEvents(events: any[]|EventModel[]): any[]|EventModel[] {
    const rslt =  events.sort((a, b) => {
      if(a.isAllday && b.isAllday)  return 0;                                 // 2x hele dag onbelangrijk
      else if (a.isAllday && !b.isAllday)  return -1;                         // hele dag A gaat voor B
      else if (!a.isAllday && b.isAllday)  return 1;                          // hele dag B gaat voor A
      if(!a.startDateTime.hasSame(b.startDateTime, 'day'))  return a.startDateTime < b.startDateTime ? -1 : 1;

      if (a.startDateTime.hasSame(b.startDateTime, 'minute')) {
        if (a.endDateTime <= b.endDateTime) return -1;                           // A & B beginnen gelijk, A eindigt VOOR B
        else if (a.endDateTime >= b.endDateTime) return 1;                       // A & B beginnen gelijk, A eindigt NA B
      }
      else if (a.startDateTime <= b.startDateTime)  return -1;                      // A begint VOOR B
      else if (a.startDateTime >= b.startDateTime)  return 1;                       // A begint NA B

      return 0;
    });
    return rslt;
  }
}
