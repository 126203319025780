/* eslint-disable no-console */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  EventModel,
  WorkHoursModel,
  CalendarTypesEnum,
  NgtrCalendarService,
  IContextAction,
  IContextActionResult,
  INewEvent,
  IViewChanged,
  INgtrCalendarOptions
} from 'ngtr-calendar';
import {
  faChevronDown,
  faChevronUp,
  faToggleOn,
  faToggleOff,
  faCalendarAlt,
  faClock,
  faCalendarWeek,
  faCalendarDay,
  faColumns,
} from '@fortawesome/free-solid-svg-icons';
import { faGithub, faTwitter, faNpm } from '@fortawesome/free-brands-svg-icons';
import { DateTime, Settings } from 'luxon';
import { IMarkedDateTime } from 'ngtr-tiny-calendar';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})

export class AppComponent {
  /**
   * FontAwesome wordt gebruikt voor het weergeven van een aantal iconen op knoppen.
   * Daarvoor wordt een library van FontAwesome gebruikt (NPM)
   */
  fontAwesome: any = {
    chevronUp: faChevronUp,        // > maar dan omhoog
    chevronDown: faChevronDown,    // < maar dan omlaag
    github: faGithub,              // icoon voor github
    twitter: faTwitter,            // icoon voor twitter
    toggleOn: faToggleOn,          // toggle-button aan
    toggleOff: faToggleOff,        // toggle button uit
    calendar: faCalendarAlt,       // calendar-buton
    clock: faClock,                // klokweergave
    calendarWeek: faCalendarWeek,  // week-kalender
    calendarDay: faCalendarDay,    // dag-calender
    menuSide: faColumns,           // side-button opschrift
    npm: faNpm,
  };

  // invloed op de calendar
  actions: IContextAction[];                                        // de contextactions voor alle events.
  calendarTypes: string[] = [];                                     // de actieve kalender types
  events: EventModel[] = [];                                             // alle events voor in de kalender.
  workHours: WorkHoursModel[];                                      // de werkuren per dag.
  currentCalendarType: CalendarTypesEnum = CalendarTypesEnum.Week;  // het huidige geselecteerde kalendertype.
  viewDate: DateTime = DateTime.local();                            // de geselecteerde datum, de rest van de app wordt daarom heen gebouwd.
  visibleDays: number[] = [1, 2, 3, 4, 5, 6, 7];                    // welke dagen zijn zichtbaar (ma = 1, di = 2, ... , za = 6, zo = 0)
  markedDateTimes: IMarkedDateTime[];

  // voor de appcomponent zelf
  consoleStr: string[] = [];          // wat wordt er in de console gelogd op het thuisscherm.
  calendarDataTab = 0;                // de geselecteerde tab in de dataviewer
  isCalendarFullwidth = false;        // show calendar in full width?
  ngtrCalendarVersion = '1.8.11';     // version number - ngtr-calendar
  ngtrTinyCalendarVersion = '0.4.1';  // version number - ngtr-tiny-calendar


  constructor(public calendarService: NgtrCalendarService, public appService: AppService) {
    Settings.defaultLocale = 'nl';
  }

  /**
   * Lifecycle hook. wordt aangeroepen zodra de component wordt aangemaakt.
   */
  ngOnInit() {
    this.calendarTypes = [CalendarTypesEnum.Week, CalendarTypesEnum.List, CalendarTypesEnum.Month, CalendarTypesEnum.Fourweeks, CalendarTypesEnum.Year];

    const settings: INgtrCalendarOptions = {
      //maxHeight: '1000px',
      visibleDays: [1, 2, 3, 4, 5],
      //visibleHours: [7,8,9,10,11,12,13,14,15,16,17,18,19,20]
    };
    this.calendarService.setOptions(settings);

    this.appService.getFakeMarkedDateTimes().subscribe(mdts => {this.markedDateTimes = mdts; });
    this.appService.getFakeEvents().subscribe(evts => { this.events = evts; console.log(this.events); });
    this.appService.getFakeContextActions().subscribe(cas => { this.actions = cas; });
    this.appService.getFakeWorkhours().subscribe(whs =>{ this.workHours = whs; });
    // console.log(this.calendarService);
  }

  /**
   * Een event toevoegen. aanmaken op de huidige datumtijd.
   * via de gewone knop
   */
  addEvent() {
    const event = new EventModel(this.events.length, DateTime.local());
    event.isEditable = true;
    event.isAllday = false;
    event.title = 'New Event ' + this.events.length;
    event.description = `${event.id}: I don't want to talk to you no more, you empty-headed animal food trough wiper! I fart in your general direction! Your mother was a hamster and your father smelt of elderberries! You don't frighten us, English pig dogs. Go and boil your bottoms, you sons of a silly person. I blow my nose at you, so-called "Arthur King," you and all your silly English K-nig-hts.`;
    event.endDateTime = event.startDateTime.plus({hours: 2});
    this.events.push(event);
    this.events = [...this.events];
  }

  /**
   * Voor methodes die nog niks doen.
   * Geeft een Error: Not implemented yet!
   * @param event voor bij de action.
   */
  onAction(evt: any) {
    throw new Error(`${evt}: NOT IMPLEMENTED YET`);
  }

  /**
   * Er is een event geselecteerd met de rechtermuisknop.
   * Dat is een context-actie.
   * Die worden gedefinieerd bij @see MaakTestActions
   * @param rslt het context-actie-model
   */
  onContextActionSelected(rslt: IContextActionResult) {
    const ca = this.actions[this.actions.findIndex((a) => a.contextActionId == rslt.contextActionId)];
    // console.log('OnContextActionSelected()', rslt, 'is ContextAction', ca);
    this.consoleStr.push(`contextAction emitted for event met eventId ${rslt.eventId} en ContextAction ${rslt.contextActionId}.`);

    const index = this.events.findIndex(e => e.id == rslt.eventId);
    switch (ca.contextActionId) {
      case 1000:  // verwijderen
        this.events.splice(index, 1);
        break;
      case 1001: // disabled action
        break;
      case 1002: // day earlier
        this.events[index].startDateTime = this.events[index].startDateTime.plus({days: 1});
        this.events[index].endDateTime = this.events[index].endDateTime.plus({days: 1});
        break;
      case 1003: // day later
        this.events[index].startDateTime = this.events[index].startDateTime.minus({days: 1});
        this.events[index].endDateTime = this.events[index].endDateTime.minus({days: 1});
        break;
      case 1004:  // warning
        alert(`This is a warning for event ${this.events[index].id}`);
        break;
      case 1005: // startijd uurtje eerderd
        this.events[index].startDateTime = this.events[index].startDateTime.minus({hours: 1});
        break;
      case 1006: // eindtijd uurtje later
        this.events[index].endDateTime = this.events[index].endDateTime.plus({hours: 1});
        break;
      default: return;
    }
    this.events = [...this.events];
  }

  // /**
  //  * Een event-grootte is aangepast
  //  * - bovenste rand is versleept
  //  * - onderste rand is versleept.
  //  * @param evtID het eventId van het event
  //  */
  // onEventResized(evtID: string|number) {
  //   // console.log('OnEventResized()', evtID);
  //   // console.log(this.events[this.events.findIndex((e: EventModel) => e.id == evtID)]);
  //   this.consoleStr.push(`eventDragged emitted: eventId = ${evtID}`);
  // }

  // /**
  //  * een event is gedragged naar een nieuwe positie.
  //  * @param evtID het calendar-ID van het event.
  //  */
  // onEventDragged(evtID: string|number) {
  //   // console.log('OnEventDragged()', evtID);
  //   // console.log(this.events[this.events.findIndex((e: EventModel) => e.id == evtID)]);
  //   this.consoleStr.push(`eventDragged emitted: eventId = ${evtID}`);
  // }

  /**
   * Er is een event geselecteerd
   * dat event kan daarna worden opgezocht met het evtID.
   * @param evtId het calendar-ID van het event.
   */
  onEventSelected(evtId: string|number) {
    // console.log('OnEventSelected()', evtId, this.events.find(e => e.id == evtId));
    this.consoleStr.push(`eventSelected emitted: eventId = ${evtId}`);
  }

  /**
   * er is een event verwijdert.
   * @param evtId het calendar-ID van het event.
   */
  onEventDelete(evtId: string|number) {
    // console.log('OnEventDelete()', evtId);
    this.consoleStr.push(`eventDelete emitted: eventId = ${evtId}`);

    const i = this.events.findIndex(e => e.id == evtId);
    this.events = this.events.splice(i, 1);
    this.events = [...this.events];
  }

  /**
   * Nieuw event aanmaken
   * daarvoor wordt een @see INewEvent gebruikt.
   * @param evt constructor-object voor een event.
   */
  onEventCreate(evt: INewEvent) {
    if (evt.isAllday) {
      this.consoleStr.push(`eventCreate emitted: ${evt.start.toFormat('dd-MM')} isAllday`);
    } else {
      this.consoleStr.push(`eventCreate emitted: ${evt.start.toFormat('dd-MM HH:mm')} - ${evt.end.toFormat('dd-MM HH:mm')}`);
    }
    // console.log('OnEventCreate()',`eventCreate emitted: ${evt.isAllday ? 'Allday' : evt.start.toFormat('dd-MM HH:mm')} ${evt.isAllday ? '' : evt.end.toFormat(' - dd-MM HH:mm')}`, evt);

    const newEvt = new EventModel(this.events.length, evt.start, evt.end, evt.isAllday);
    newEvt.title = 'New - Monty Ipsum';
    newEvt.description = 'Ah, I see you have the machine that goes ping. This is my favorite.';
    newEvt.backgroundColor = generateHexColor();
    newEvt.textColor = newEvt.backgroundColor.substr(1) > '667788' ? '#000' : '#fff';
    this.events.push(newEvt);
    this.events = [...this.events];
  }

  /**
   * Er kunnen 2 dingen gebeurt zijn
   * - de datum van de view is aangepast.
   * - het type view is aangepast
   * @param vd het viewchangedobject voor de afhandeling
   */
  onViewDateChanged(vd: IViewChanged) {
    this.currentCalendarType = vd.newType;
    this.viewDate = vd.viewDate;
    this.consoleStr.push('viewDateChanged emitted');
    // console.log(vd);
  }

  onTinyViewDateChanged(datetime: DateTime) {
    const vc: IViewChanged = {
      viewDate: datetime,
      newType: this.currentCalendarType as CalendarTypesEnum
    };
    const txt =`new ViewDate selected in ngtr-tiny-calendar: ${datetime.toISO()}`;
    // console.log(txt);
    this.consoleStr.push(txt);
    this.onViewDateChanged(vc);
  }
}



function generateHexColor(): string {
  const r1 = Math.floor(Math.random() * 16).toString(16);
  const r2 = Math.floor(Math.random() * 16).toString(16);

  const g1 = Math.floor(Math.random() * 16).toString(16);
  const g2 = Math.floor(Math.random() * 16).toString(16);

  const b1 = Math.floor(Math.random() * 16).toString(16);
  const b2 = Math.floor(Math.random() * 16).toString(16);
  return `#${r1}${r2}${g1}${g2}${b1}${b2}`;
}
